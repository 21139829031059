.banner-container{
    width: 90%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 20%);
    margin: auto;
    height: fit-content;
    position: fixed;
    bottom: 0;
    left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: auto!important;
    padding-block: 4px;
}

.banner-cookie__text {
    color: white;
    font-size: .9rem;
    margin: auto;
    margin-block-end: 0;
    margin-block-start: 0;
}

.banner-cookie__privacy-policies {
    color: white;
    font-size: .9rem;
    font-weight: bold;
    margin: 0;
}


.privacy-policies__link {
    color: white;
    text-decoration: none;
    margin: 0;
}

.banner-cookie__privacy-policies-button-close {
    height: fit-content;
    margin-inline: 16px;
    margin-block-start: 4px;
    cursor: pointer;
}

.cookie-banner_accept-button {
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.515);
}

.cookie-banner_accept-button p {
    margin-block: 0;
    padding-inline: 16px;
    padding-block: 6px;
    overflow: hidden;
}