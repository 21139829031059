.feature-container {
    display: flex;
    width: 100%;
}

.feature-container__check{
    width: 48px;
    height: 48px;
    /* margin: 8px 16px; */
}

.feature-container__check-img {
    width: 17.5px;
    height: 17.5px;
    vertical-align: middle;
    margin-block: 2px;

}

.feature-container p {
    text-align: start;
    color: var(--primary-color);
    font-size: 0.75rem;
    font-weight: bold;
    margin: 1px;
    line-height: 1.5rem;
}

.feature-container__check-img-container {
    height: fit-content;
    width: fit-content;
    margin-inline-end: 8px;
    line-height: 1.5rem;
}