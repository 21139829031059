.blog-container {
    position: relative;
   /*  max-width: 350px; */
    width: 100%;
    align-self: center;
   /*  min-width: 180px;
    max-width: 250px; */
    display: flex;
    flex-direction: column;
   /*  border: 1px solid var(--branch-gray);
    box-shadow: 0 2px 3px rgba( 0, 0, 0, 0.25); */
}

/* .logo_eclinic-blog {
    max-width: 50px;
    position: absolute;
    top: 0;
    left: 0;
    margin-inline-start: 8px;
    margin-block-start: 8px;
} */

.blog-container__img {
    min-width: 180px;
    width: 100%;
    height: auto;
    object-fit:scale-down;
}

.blog-container__title {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--primary-color);
    margin: auto;
    padding: 8px 4px;
}

@media only screen and (min-width: 768px) {
    .blog-container {
        width: 100%;
        padding: 16px;
    }
}