.hubspot-form-drhelp {
    width: 100%;
    margin:auto;
    background-color: white;
    padding: 32px 16px;
}

.hubspot-form-container {
    width: 70%;
    margin: auto;
}