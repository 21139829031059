.frequent-question-section {
    height: fit-content;
    padding: 16px;
}

.frequent-question-section__title {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin: 24px auto;
}

.frequent-question-section__questions {
    display: flex;
    flex-direction: column;
}


@media only screen and (min-width: 768px) {

    .frequent-question-section {
        height: fit-content;
        padding: 36px;
    }

    .frequent-question-section__title {
        color: var(--primary-color);
        font-size: 1.75rem;
        margin: 36px auto;
    }
}

