.user-preference-us-section {
    background-color: var(--branch-light-aquamarine);
    width: 100%;
    margin: auto;
    padding: 20px 0;
}

.user-preference-us-container {
   
    width: 100%;
    margin: auto;
    max-width: 1080px;
    
}

.user-preference-us__title {
    color: white;
    font-size: 1.75rem;
    line-height: 2.4rem;
    font-weight: bold;
    margin: 24px auto;
    margin-block-start: 16px;
    margin-block-end: 36px;
    width: 90%;
}

.user-preference_feature {
    min-width: 260px;
    margin-inline: auto;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-content:stretch;
}

.user-preference_feature-container{
    display: flex;
    justify-items: start;
    flex-direction: column;
    margin-top: 8px;
}

.user-preference_feature h3 {
    color: white;
    font-size: .9rem;
    margin: 8px auto;
    text-align: center;
}

.user-preference_feature p {
    color: white;
    font-size: .75rem;
    font-weight: 500;
    margin: 8px auto;
    width: 75%;
    text-align: center;
}

.user-preference_feature-icon {
    margin-inline: auto;
    margin-block-start: 0px;
    margin-block-end: 12px;
}

.user-preference_feature-icon-img {
    object-fit: cover;
    max-width: 70px;
    min-width: 60px;
    width: 100%;
}

@media only screen and (min-width: 768px){
    .user-preference_feature-container{
        flex-direction: row;
    }
}

