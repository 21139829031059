.eclinic-advantages-section-container {
    width: 100%;
    background-color: white;
}

.eclinic-advantages-section {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: auto;
    padding: 36px 0px;
    background-color: white;
}
.eclinic-advantages-section__img-container {  
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.eclinic-advantages-section__img {  
  /*   object-fit: cover;
    max-width: 600px;
    min-width: 300px;
    margin: auto; */
    width: 120%;
}
.eclinic-advantages-section__advages-features {
    width: 80%;
    margin: auto;
}
 
@media only screen and (min-width: 768px) {

    .eclinic-advantages-section {
        display: flex;
        flex-direction: row;
        max-width: 1200px;
    }

    .eclinic-advantages-section__img-container {  
        width:50%;
    }

    .eclinic-advantages-section__img {  
      /*   object-fit: cover; */
      /*   width: 100%;
        max-width: 500px; */
        width: 120%;
    }

    .eclinic-advantages-section__advages-features {
        width: 50%;
    }

}