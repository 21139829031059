.for-medical-entities-article {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    padding-inline: 8px;
    padding-block: auto;
}

.for-clinic {
    display: flex;
}

.for-consulting-room{
    display: flex;
    align-content: center;
}

.for-clinic__icon {
    width: 60px;
    height: 60px;
    align-self: center; 
    border-radius: 50%;
    background-color: transparent;
}

.for-clinic__icon-img {
    max-width: 60px;
    min-width: 60px;
    width: 100%;
    height: auto;
    object-fit: cover;
    fill-opacity: 1
}

.for-entity_title {
    font-size: .8rem;
    align-self: center;
    font-weight: 500;
    padding-inline: 8px;
    color: var(--primary-color); 
    text-align: start;
}

.for-specialty_title {
    font-size: .8rem;
    align-self: center;
    font-weight: 500;
    padding-inline: 8px;
    color: var(--primary-color); 
    text-align: start;
}

.for-who-section__vertical-divider {
    width: 2px;
    background-color: white;
}

@media only screen and (min-width: 768px) {

    .for-medical-entities-article {
        width:  47.5%;
    }

    .for-entity_title {
        font-size: .9rem;
        line-height: normal;
        align-self: center;
        font-weight: 500;
        padding-inline: 16px;
        color: var(--primary-color); 
    }
    
 }