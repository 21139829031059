.blog-article-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    border: 8px;
    margin: 24px auto;
    box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.2);
    border-bottom: .25px solid rgba(124, 124, 124, 0.2);
    max-width: 720px;
}

.blog-article-section__title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--primary-color);
    width: 90%;
    margin-inline: auto;
}

.blog-article-section__publish-date {
    font-size: 0.75rem;
    color: #ababab;
    width: 100%;
    margin-inline: auto; 
}

.blog-article-section__img-container {
    min-width: 280px;
    width: 100%;
    height: auto;
    position: relative;
}

.blog-article-section__img-cover {
    background-color: black;
    opacity: 0.25;
    z-index: 5;
    width: 100%;
    height: 100%;
    position: absolute;
}

.blog-article-section__img {
    min-width: 280px;
    width: 100%;
    height: auto;
    object-fit: cover;
 /*    max-height: 320px; */
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.blog-article-section__description {
    font-size: .9rem;
    text-align: justify;
    color: #878787;
}

.blog-article-section__continue-reading {
    background-color: white;
    padding-inline: 16px;
    padding-block: 8px;
    border: 2px solid var(--primary-color);
    width: fit-content;
    margin-inline: auto;
    border-radius: 4px;
    margin-block: 24px;
}

.blog-article-section__continue-reading a, .blog-article-section__continue-reading a:visited {
  text-decoration: none;
  color: var(--primary-color); 
}

.blog-article-section__continue-reading:hover {
    background-color: var(--primary-color); 
    color: white;
}

.blog-article-section__continue-reading:hover a {
    color: white;
}


.blog-article-section__continue-reading:hover a:visited{
    color: white;
}


@media only screen and (min-width: 768px) {

    .blog-article-section__title {
        font-size: 1.5rem;
        line-height: 2rem;
        width: 90%;
        margin-bottom: 8px;

    }

    .blog-article-section {
        margin: 36px auto;
    }

    .blog-article-section__continue-reading {
        padding-block: 4px;
        font-size: .75rem;
    }

    .blog-article-section__publish-date {
        color: #ababab;
        width: 100%;
        margin-inline: auto; 
        margin-block: 20px;
    }
    
}