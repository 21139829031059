.web-page-own-domain-advise-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    background-color: white;
    margin-inline: auto;
    margin-block: 16px;
    padding-inline: 16px;
    padding-block: 12px;
    box-shadow: 0px 0.5px 10px rgb( 0, 0, 0, .33);  
    border-radius: 8px;
}

.web-page-own-domain-advise-container__advertisement {
    display: flex;
    flex-direction: row;
}

.web-page-own-domain-advise-container__advertisement-icon-container {
    display: flex;
    align-self: center;
    margin-inline: 8px;
    margin-block: 16px;
}

.web-page-own-domain-advise-container__advertisement-icon {
   min-width: 60px;
   height: auto;
   max-width: 80px;

}

.web-page-own-domain-advise-container__title {
    color: var(--primary-color);
    font-size: 1.5rem;
    text-align: start;
    line-height: 1.8rem;
    margin-block: 8px;
}

.web-page-own-domain-advise-container__text {
    margin-block: 4px;
    font-size: .85rem;
    color: #808080;
    text-align: start;
}

.web-page-own-domain-advise-container__info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8px;
}

.web-page-own-domain-to_expert-button {
    background-color: var(--branch-light-aquamarine);
    width: fit-content;
    border-radius: 8px;
    font-size: 1rem;
    color: white;
    padding: 12px 32px;
    border: 1px solid var(--branch-light-aquamarine);
    align-self: center;
    justify-self: center;
    cursor: pointer;
}

.web-page-own-domain-to_expert-button-conatainer {
    display: flex;
    padding-inline: 32px;
    padding-block: 16px;
}

@media only screen and (min-width: 768px){
    .web-page-own-domain-advise-container {
        flex-direction: row;
        margin-block: 32px;
    }

    .web-page-own-domain-advise-container__title {
        font-size: 1.25rem;
        text-align: start;
        line-height: 1.5rem;
        margin-block: 8px;
    }
    
    .web-page-own-domain-advise-container__text {
        margin-block: 4px;
        font-size: .9rem;
        color: #808080;
        text-align: start;
    }

    .web-page-own-domain-advise-container__advertisement-icon-container {
        display: flex;
        align-self: center;
        margin-inline: 16px;
        margin-block: 16px;
    }
    
}

