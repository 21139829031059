.review-in-price-section-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--branch-light-aquamarine);
    height: fit-content;
    margin: auto;
    justify-content: center;
}

.review-in-price-review-container {
    display: flex;
    min-width: 260px;
    max-width: 500px;
    background-color: white;
    height: fit-content;
    width: 90%;
    margin-block: 8px;
    border-radius: 8px;
    margin-inline: auto;
    justify-self: center;
}


.review-in-price-review__review-text {
    font-style: oblique;
    margin: 8px auto;
    font-size: 0.75rem;
    text-align: justify;
    width: 75%;
    align-self: center;
}

.review-in-price-review__img {
    width: 50px;
    height: 50px;
  /*   background-color: var(--branch-light-aquamarine); */
    border-radius: 50%;
    align-self: center;
    margin: 8px;
    margin-inline: 16px;
}

.review-in-price-review__doctor-name {
    text-transform: uppercase;
}

.is-feature-included {
    color: red;
}

.is-feature-included.parent {
    height: 200px;
}


@media only screen and (min-width: 768px){

    .review-in-price-section-container {
        flex-direction: row;
        justify-content: center;
        align-content: stretch;
        padding-block: 16px;
    }

    .review-in-price-review-container {
        height: auto;
        margin-inline: 16px;
    }
}