.customer-reviews-container {
    display: block;
    background-color: var(--branch-light-aquamarine);
    padding: 20px 0px;
}

.customer-reviews-container__title {
    color: var(--primary-color);
    font-size: 1.75rem;
    margin: auto;
}

.customer-review__review {
    display: flex;
    height: auto;
    flex-direction: column;
    padding: 20x 16px;
    justify-items: center;
    justify-content: center;
}


@media screen and (min-width: 768px) {
    .customer-review__review {
        flex-direction: row;
        justify-items: center;
    }

    .customer-review__review {
    }
}