.header-navbar-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    font-size: .75rem;
}

.header-navbar-container a {
    text-decoration: none;
}

.header-navbar__modules-button-container, .header-navbar__resource-button-container {
    position: relative;
    display: flex;
    flex-direction: row;
}


.modules-links, .resources-links{
    list-style-type:none;
    text-align: start;
    margin-inline-start: 0px;
    padding-inline-start: 24px;
    padding-inline-end: 16px;
}

.modules-links li, .resources-links li {
  font-size: .8rem;  
  width: 100%;
  margin-block: 8px;
  color: var(--primary-color);
}

.modules-links li:hover, .resources-links li:hover {
    color: var(--branch-light-aquamarine);
  }

.modules-links-container, .resources-links-container{
    position: absolute;
    top: 60%;
    left: 66%;
    z-index: 4;
    width: 190%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, .3);
    background-color:white;
    border-radius: 8px;
}



.header-navbar__tell-to-expert-button {
    background-color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    align-self: center;
    margin-inline: 8px;
}
.header-navbar__tell-to-expert-button:hover {
    color: white;
    font-weight: bold;
}

.header-navbar__price-button {
    background-color: white;
    color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border: 1px solid var(--branch-opaque-aquamarine);
    border-radius: 8px;
    align-self: center;
    margin-inline: 8px;
}

.header-navbar__price-button:hover {
    font-weight: bold;
}

.header-navbar__resource-button, .header-navbar__resource-button:visited {
    margin-inline: 16px;
    color: var(--primary-color);
    align-self: center;
    cursor: pointer;
}

.header-navbar__modules-button, .header-navbar__modules-button:visited {
    margin-inline: 16px;
    color: var(--primary-color);
    align-self: center;
    cursor: pointer;
}

.header-navbar__resource-button:hover {
    color: var(--branch-opaque-aquamarine);
}

.header-navbar__modules-button:hover {
    color: var(--branch-opaque-aquamarine);
}

.resource-chevron-icon {
    vertical-align: middle;
}
.resource-chevron-icon:hover {
    color: var(--branch-opaque-aquamarine);
}

.modules-chevron-icon {
    vertical-align: middle;
}

.modules-chevron-icon:hover {
    color: var(--branch-opaque-aquamarine);
}

.header-navbar__modules-button > span, .header-navbar__resource-button > span {
    font-size: 1rem;
}

@media only screen and  (min-width: 768px) and (max-width: 840px) {

    .header-navbar__resource-button, .header-navbar__resource-button:visited {
        margin-inline: 8px;
    }

    .header-navbar__modules-button, .header-navbar__modules-button:visited {
        margin-inline: 8px;
    }
    
}