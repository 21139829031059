@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500&display=swap);
body {
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #0c3a5b;
  --branch-gray: #f4f5f1;
  --branch-opaque-aquamarine: #19c1ce;
  --branch-light-aquamarine: #1ddbd6;
  --branch-smooth-blue: #d9fbff;
  --branch-light-blue: #008eaf;
  --h1-default-weight: 500;
  --branch-line-height: 1.4rem;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.App {
  line-height: 1.25rem;
  background-color: var(--branch-gray);
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-container {
  text-align: center;
  background-color:white;
  display: flex;
  align-content: center;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
  flex-wrap: wrap;
}

.header-logo-container {
  padding: 24px 0;
  width: 66%;
  margin: auto;
}

.header-logo-container__img {
    min-width: 120px;
    max-width: 180px;
}

.header__menu {
  display: flex;
  width: 33%;
  padding-inline: 32px;
}

/* 
.header__menu svg {
  align-self: center;
  justify-self: end;
} */

.header__menu-navbar {
  opacity: 0;
  position: fixed;
  padding: 10px 10px;
  left: 100%;
  transform: translateX(-110%);
  background-color: white;
  list-style: none;
  text-align: end;
  z-index: 5;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, .8);
  border-radius: 7px;
  transition: opacity .1s ease;
}

/* .header__menu-navbar a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 0.9rem;
  line-height: 1.5rem;
} */

.header__menu-navbar a:hover {
  color: var(--branch-light-aquamarine);
}


.p-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .black-background_overlay {
  z-index: 3;
  opacity: 0;
  transition: opacity .5s ease;
  background-color: rgba(0, 0, 0, .5);
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
} */



@media only screen and (min-width: 768px) {

  .header-container {
    text-align: center;
    background-color:white;
    display: flex;
    align-content: center;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
    flex-wrap: wrap;
  }

  .header__menu-navbar {
    opacity: 1;
    display: inline-block;
    position: absolute;
    list-style-type: none;
    padding: 10px 10px;
    left: 100%;
    background-color: var(--ba);
    text-align: end;
    box-shadow: none;
    border-radius: 7px;
    transition: none;
    font-weight: bold;
  }

  .header-logo-container {
    display: flex;
    justify-content: flex-start;
    width: 30%;
    padding-inline-end: 0%;
    padding-inline-start: 2.5%;
  }

  .header__menu {
    width: 70%;
    justify-content: flex-end;
    padding-inline-end: 2.5%;
    padding-inline-start: 0%;
  }

 /*  .black-background_overlay {
    display: block;
    opacity: 1.0;
  } */

}


@media only screen and (min-width: 1024px) {

  .header-logo-container {
    display: flex;
    width: 25%;
    padding-inline-end: 0%;
    padding-inline-start: 10%;
  }

  .header__menu {
    display: flex;
    width: 75%;
    justify-content: flex-end;
    padding-inline-end: 10%;
    padding-inline-start: 0%;
  }
  
}


.header-navbar-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    font-size: .75rem;
}

.header-navbar-container a {
    text-decoration: none;
}

.header-navbar__modules-button-container, .header-navbar__resource-button-container {
    position: relative;
    display: flex;
    flex-direction: row;
}


.modules-links, .resources-links{
    list-style-type:none;
    text-align: start;
    margin-inline-start: 0px;
    padding-inline-start: 24px;
    padding-inline-end: 16px;
}

.modules-links li, .resources-links li {
  font-size: .8rem;  
  width: 100%;
  margin-block: 8px;
  color: var(--primary-color);
}

.modules-links li:hover, .resources-links li:hover {
    color: var(--branch-light-aquamarine);
  }

.modules-links-container, .resources-links-container{
    position: absolute;
    top: 60%;
    left: 66%;
    z-index: 4;
    width: 190%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, .3);
    background-color:white;
    border-radius: 8px;
}



.header-navbar__tell-to-expert-button {
    background-color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    align-self: center;
    margin-inline: 8px;
}
.header-navbar__tell-to-expert-button:hover {
    color: white;
    font-weight: bold;
}

.header-navbar__price-button {
    background-color: white;
    color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border: 1px solid var(--branch-opaque-aquamarine);
    border-radius: 8px;
    align-self: center;
    margin-inline: 8px;
}

.header-navbar__price-button:hover {
    font-weight: bold;
}

.header-navbar__resource-button, .header-navbar__resource-button:visited {
    margin-inline: 16px;
    color: var(--primary-color);
    align-self: center;
    cursor: pointer;
}

.header-navbar__modules-button, .header-navbar__modules-button:visited {
    margin-inline: 16px;
    color: var(--primary-color);
    align-self: center;
    cursor: pointer;
}

.header-navbar__resource-button:hover {
    color: var(--branch-opaque-aquamarine);
}

.header-navbar__modules-button:hover {
    color: var(--branch-opaque-aquamarine);
}

.resource-chevron-icon {
    vertical-align: middle;
}
.resource-chevron-icon:hover {
    color: var(--branch-opaque-aquamarine);
}

.modules-chevron-icon {
    vertical-align: middle;
}

.modules-chevron-icon:hover {
    color: var(--branch-opaque-aquamarine);
}

.header-navbar__modules-button > span, .header-navbar__resource-button > span {
    font-size: 1rem;
}

@media only screen and  (min-width: 768px) and (max-width: 840px) {

    .header-navbar__resource-button, .header-navbar__resource-button:visited {
        margin-inline: 8px;
    }

    .header-navbar__modules-button, .header-navbar__modules-button:visited {
        margin-inline: 8px;
    }
    
}
.hamburger-menu-container {
    position: relative;
    align-self: center;
    justify-self: center;
    margin: auto;
    display: flex;
}

.hamburger-menu__main-page-icon {
    align-self: center;
}

.background-black-overlay {
    position: fixed;
    top: -5vh;
    right: 0vw;
    width: 100vw;
    height: 150vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 5;
}

.hamburger-menu__options-container {
    background-color: white;
    z-index: 10;
    width: 103vw;
    height: 100vh;
   /*  height: fit-content; */
    position: fixed;
    top: 0vh;
    right: 0vw;
    padding-block: 24px;
  /*  border-bottom-left-radius: 8px;
   border-top-left-radius: 8px; */
}

.modules-links-container-hamburger-menu {
    padding-block: 4px;
    padding-inline: 5%;
}

.resources-links-hamburguer-menu {
    list-style: none;
    padding-inline-start: 10%;
    margin-block: 4px;
}

.resources-links-hamburguer-menu li {
    text-align: start;
    padding-block: 4px;
    font-size: .9rem;
    font-weight: bold;
    color: var(--primary-color);
}

.modules-links-hamburger-menu {
    list-style: none;
    margin-block: 0px;
    padding-inline: 5%;
}

.modules-links-hamburger-menu li {
    text-align: start;
    padding-block: 4px;
    font-size: .9rem;
    font-weight: bold;
}

.modules-links-hamburger-menu a, .resources-links-hamburguer-menu a {
    text-decoration: none;
}

.modules-links-hamburger-menu a:visited,  .resources-links-hamburguer-menu a:visited {
    color: var(--primary-color);
}

.hamburger-menu__price-button {
    background-color: white;
    color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border: 1px solid var(--branch-opaque-aquamarine);
    border-radius: 8px;
    align-self: center;
    width: 234px;
    margin-inline: auto;
    margin-block: 16px;
}


.hamburger-menu__price-button > a {
    color: var(--branch-light-aquamarine);
    text-decoration: none;
}

.hamburger-menu__menu-item__container {
    display: flex;
    justify-content: space-between;
    padding-block: 4px;
    padding-inline: 5%;
}

.hamburger-menu__menu-entries-container {
    display: flex;
    flex-direction: column;
    padding-block-start: 24px;
    padding-block-end: 48px;
}

.hamburger-menu__menu-item {
    text-align: start;
    margin-block: 0px;
    align-self: center;
    font-size: .9rem;
    font-weight: bold;
    color: var(--primary-color);
}

.hamburger-menu__header {
    display: flex;
    padding-inline: 4%;
    width: 100%;
    padding-block-end: 36px;
    justify-content: space-between;
}

.hamburger-menu__header-img {
    min-width: 90px;
    max-width: 120px;
    height: auto;
    object-fit: cover;
}

.hamburger-menu__down-icon {
    align-self: center;
}

.hamburger-menu__down-icon-rotation-enter {
    
}

.hamburger-menu__down-icon-rotation-enter-active {
    transform: rotate(180deg);
    transition-duration: 500ms;
    transition-property: transform;
    transition-timing-function: ease;
}

.hamburger-menu__down-icon-rotation-enter-done {
    transform: rotate(180deg);
}

.hamburger-menu__down-icon-rotation-exit {
    transform: rotate(180deg);
}


.hamburger-menu__down-icon-rotation-exit-active {
    transform: rotate(0deg);
    transition-duration: 500ms;
    transition-property: transform;
    transition-timing-function: ease;
}

.hamburger-menu__down-icon-rotation-exit-done {
    transform: rotate(0deg);
}

.hamburger-menu__modules-items-menu {
    height: 0px;
    overflow: hidden;
}


.hamburger-menu__modules-items-menu-enter-active {
    height: 180px;
    transition: height 1000ms ease;
}

.hamburger-menu__modules-items-menu-enter-done {
    height: 180px;
}

.hamburger-menu__modules-items-menu-exit {
    height: 0px;
    transition: height 1000ms ease;
}


.hamburger-menu__resources-items-menu {
    height: 0px;
    overflow: hidden;
}


.hamburger-menu__resources-items-menu-enter-active {
    height: 90px;
    transition: height 1000ms ease;
}

.hamburger-menu__resources-items-menu-enter-done {
    height: 90px;
}

.hamburger-menu__resources-items-menu-exit {
    height: 0px;
    transition: height 1000ms ease;
}




.to_expert-button {
    background-color: var(--branch-light-aquamarine);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    font-size: 1rem;
    color: white;
    font-weight: bold;
    padding: 12px 32px;
    border: 1px solid var(--branch-light-aquamarine);
    cursor: pointer;
    align-self: center;
    justify-self: center;
}

.to-expert-button-link {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-block: auto;
} 


.landing-page-head-container {
    display: grid;
    height: auto;
    background-color: white;
    padding-block: 16px;
}

.landing-page-head-container_img {
    width: 90%;
    height: auto;
}

.landing-page-head-container_title {
    padding: 0;
}

.landing-page-head-container h1 {
    font-weight: bold;
    font-size: 1.65rem;
    color: var(--primary-color);
    text-align: center;
    margin-block: 0;
    margin-inline: 8px;
}

.landing-page-head-container p {
    width: 90%;
    margin: 30px auto;
    font-weight: 400;
    font-size: 0.9rem;
    color: var(--primary-color);
    text-align: center;
}


.landing-page-head-container span {
    font-weight: bold;
    font-size: 1.65rem;
    color: var(--branch-light-aquamarine);
}

.landing-page-head-container_button {
    color: white;
    font-weight: var(--h1-default-weight);
    padding: 10px 15px;
    text-decoration: none;
    background-color: var(--branch-opaque-aquamarine);
    border-radius: 5px;
}

.demoeclinic-container {
    margin: 20px;
}

.text-writter-c {
    align-self: center;
}

.landing-page-head-container_title-text-container {
    line-height: 2.4rem;
}

@media only screen and (min-width: 768px){

    .landing-page-head-container h1 {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-weight: bold;
        font-size: 2.4rem;
    }

    .landing-page-head-container_title {
        padding: 36px 0;
    }

    .landing-page-head-container span {
        font-weight: bold;
        font-size: 2.4rem;
    }

    .landing-page-head-container p {
        width: 75%;
        margin: 36px auto;
        font-weight: 400;
        font-size: 1.5rem;
    }

    .landing-page-head-container_button {
        color: white;
        padding: 20px 50px;
        font-weight: bolder;
        font-size: 1.2rem;
        text-decoration: none;
        background-color: #1ddbd6;
        border-radius: 10px;
    }
    
.demoeclinic-container {
    margin: 68px auto;
   }

   
   .landing-page-head-container_title-text-container {
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: bold;
    }
 }

 @media only screen and (min-width: 1024px) {

    
.landing-page-head-container_title {
    padding: 12px 0;
}

    .landing-page-head-container p {
        width: 95%;
        margin: 32px auto;
        font-weight: 500;
        font-size: 1.25rem;
    }

    .demoeclinic-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 8px auto;
            width: 380px;
            height: auto;
       }

       .landing-page-head-container_button {
            color: white;
            font-weight: var(--h1-default-weight);
            padding: 12px 36px;
    }

 }
.eclinic-description-section {
    background-color: white;
    width: 100%;
}

.eclinic-description-container {
    padding: 50px 0;
}

.eclinic-description-2-subsection {
    width: 100%;
    background-color: var(--branch-gray);
    padding-block: 36px;
}

.eclinic-description-container__title, .eclinic-description-2-container__title, .eclinic-description-3-container__title {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--primary-color);
    width: 100%;
    margin-block: 16px;
    font-weight: bold;
    text-align: center;
}

.eclinic-description-3-container__description {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    height: auto;
    order: 2;
}

.eclinic-description-container__title span{
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--branch-light-aquamarine);
}

.eclinic-description-container__text, .eclinic-description-2-container__text {
    color: var(--primary-color);
    width: 90%;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
    margin: 8px auto;
}

.eclinic_description__image {
    height: auto;
    object-fit: cover;
    width: 100%;
}

.eclinic-description-2-container__img {
    max-width: 280px;
    width: 100%;
    height: auto;
    margin: auto;
}

.featurechecks, .eclinic-description-3_features {
   padding: 16px;
   padding-inline-start: 32px;
}

.eclinic-description-3-container__container {
    display: flex;
    max-width: 280px;
    width: 100%;
    height: auto;
    order: 1;
    margin: auto;
}

.eclinic-description-3 {
    width: 100%;
    margin: auto;
    background-color: rgba(217, 251, 255, .45);
}

.eclinic-description-3-container {
    display: flex;
    width: 100%;
    max-width: 1080px;
    margin: auto;
    height: auto;
    padding: 50px 0;
    flex-wrap: wrap;
}

.eclinic-description-4 {
    width: 100%;
    background-color: white;
}

.eclinic-description-4-container { 
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    width: 100%;
    margin: auto;
    height: auto;
    padding: 50px 0;
    flex-wrap: wrap;
 
}

.eclinic-description-3__marketplace-video {
    height: 80vh;
    margin: auto;
}

.eclinic-description-3-container__text {
    color: var(--primary-color);
    text-align: justify;
    font-size: 1rem;
    width: 90%;
    line-height: 1.5rem;
    margin: 24px auto;
}

.featureimage {
           order: 2;
           display: flex;
           justify-items: center;
           align-items: center;
           width: 100%;
 }

 .eclinic-description-1-container {
     width: 100%;
     padding-inline-start: 0px;
 }


@media only screen and (min-width: 768px) {

    .eclinic-description-container {
        display: flex;
        width: 90%;
        max-width: 1080px;
        margin: auto;
 /*        grid-template-columns: 25% 25% 25% 25%;
        grid-template-areas: 'feature feature featureimage featureimage'
                             'feature feature featureimage featureimage'
                             'featurechecks featurechecks featureimage featureimage'; */
        height: auto;
        padding: 50px 0;
    }

    .eclinic-description-1-container {
        width: 100%;
    }

    .eclinic-description-2-container {
        display: flex;
        width: 90%;
        max-width: 1080px;
        margin: auto;
        height: auto;
        padding: 50px 0;
        background-color: var(--branch-gray);
    }

    .eclinic-description-4-container { 
        flex-direction: row;
        width: 90%;
    }

    .eclinic-description-2-container__container {
        display: flex;
        max-width: 500px;
        width: 50%;
        height: auto;
    }

    .eclinic-description-3-container {
        width: 90%;
    }

    .eclinic-description-3-container__container {
        display: flex;
        max-width: 500px;
        width: 50%;
        height: auto;
        order: 3;
    }

    .eclinic-description-2-container__img {
        max-width: 500px;
        min-width: 280px;
        width: 100%;
        height: auto;
    }

    .eclinic-description-3_features {
        margin-inline-start: 0%;
    }

    .eclinic-description-2-container__description {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-inline-start: 16px;
        margin: auto;
        height: auto;
    }

    .eclinic-description-3-container__description {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-inline-start: 16px;
        margin: auto;
        height: auto;
        order: 1;
    }

    .eclinic-description-2-container__text {
        text-align: start;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-inline: 0px;
        margin-block: 16px;
        margin: 24px 0;
    }

    .eclinic-description-3-container__title, .eclinic-description-2-container__title, .eclinic-description-container__title { 
        text-align: start;
        font-size: 2rem;
        line-height: 3rem;
        margin-block: 8px;
    }

    .eclinic-description-3-container__text {
        text-align: start;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-block: 8px;
        margin-inline: 0px;
    }


    .eclinic-description-container__text {
        color: var(--primary-color);
        width: 100%;
        font-size: 1.25rem;
        text-align: start;
        margin: 8px 0px;
        line-height: 2rem;
    }

    .featureimage {
 /*        grid-area: featureimage; */
        order: 2;
        display: flex;
        justify-items: center;
        align-items: center;
        width: 50%;
    }

    .feature {
   /*      grid-area: feature; */
        padding-inline-start: 16px;
        order: 1;
        width: 50%;
    }

    .featurechecks {
        margin-inline-start: 0%;
     /*    grid-area: featurechecks; */
    }
}
.feature-container {
    display: flex;
    width: 100%;
}

.feature-container__check{
    width: 48px;
    height: 48px;
    /* margin: 8px 16px; */
}

.feature-container__check-img {
    width: 17.5px;
    height: 17.5px;
    vertical-align: middle;
    margin-block: 2px;

}

.feature-container p {
    text-align: start;
    color: var(--primary-color);
    font-size: 0.75rem;
    font-weight: bold;
    margin: 1px;
    line-height: 1.5rem;
}

.feature-container__check-img-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-inline-end: 8px;
    line-height: 1.5rem;
}
.user-preference-us-section {
    background-color: var(--branch-light-aquamarine);
    width: 100%;
    margin: auto;
    padding: 20px 0;
}

.user-preference-us-container {
   
    width: 100%;
    margin: auto;
    max-width: 1080px;
    
}

.user-preference-us__title {
    color: white;
    font-size: 1.75rem;
    line-height: 2.4rem;
    font-weight: bold;
    margin: 24px auto;
    margin-block-start: 16px;
    margin-block-end: 36px;
    width: 90%;
}

.user-preference_feature {
    min-width: 260px;
    margin-inline: auto;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-content:stretch;
}

.user-preference_feature-container{
    display: flex;
    justify-items: start;
    flex-direction: column;
    margin-top: 8px;
}

.user-preference_feature h3 {
    color: white;
    font-size: .9rem;
    margin: 8px auto;
    text-align: center;
}

.user-preference_feature p {
    color: white;
    font-size: .75rem;
    font-weight: 500;
    margin: 8px auto;
    width: 75%;
    text-align: center;
}

.user-preference_feature-icon {
    margin-inline: auto;
    margin-block-start: 0px;
    margin-block-end: 12px;
}

.user-preference_feature-icon-img {
    object-fit: cover;
    max-width: 70px;
    min-width: 60px;
    width: 100%;
}

@media only screen and (min-width: 768px){
    .user-preference_feature-container{
        flex-direction: row;
    }
}


.customer-review {
    width: 90%;
    display: flex;
    flex-direction: column;
    min-width: 220px;
    max-width: 320px;
    align-self: center;
    margin: 0px auto;
    padding-top: 16px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: white;
}

.customer-review__item-container {
    display: flex;
    align-content: space-between;
    min-width: 200px;
    max-width: 350px;
    margin-block: 16px;
}

.customer-review__footer {
    width: 100%;
    height: 75px;
    padding: 10px;
    background-color: var(--branch-smooth-blue);
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
}

.customer-review__footer p {
    margin: auto;
}

.customer-review__text {
    font-style: oblique;
    width: 90%;
    height: 110px;
    margin: 8px auto;
    font-size: 0.75rem;
    text-align: center;
}

.customer-review__footer-name {
    color: var(--primary-color);
    font-weight: 500;
    font-size: .9rem;
    margin: auto;
    padding: 0;
    text-align: center;
}

.customer-review__footer-specialty {
    padding: 0;
    margin: 0;
    font-size: .8rem;
    color: var(--branch-light-aquamarine);
}

.customer-review__footer-medical-entity {
    padding: 0;
    margin: 0;
    font-size: .8rem;
    color: var(--branch-light-aquamarine);
}



/* telmedia 
finaznas agendaminetos de citas
historia clinica */
.customer-reviews-container {
    display: block;
    background-color: var(--branch-light-aquamarine);
    padding: 20px 0px;
}

.customer-reviews-container__title {
    color: var(--primary-color);
    font-size: 1.75rem;
    margin: auto;
}

.customer-review__review {
    display: flex;
    height: auto;
    flex-direction: column;
    padding: 20x 16px;
    justify-items: center;
    justify-content: center;
}


@media screen and (min-width: 768px) {
    .customer-review__review {
        flex-direction: row;
        justify-items: center;
    }

    .customer-review__review {
    }
}
.blog-container {
    position: relative;
   /*  max-width: 350px; */
    width: 100%;
    align-self: center;
   /*  min-width: 180px;
    max-width: 250px; */
    display: flex;
    flex-direction: column;
   /*  border: 1px solid var(--branch-gray);
    box-shadow: 0 2px 3px rgba( 0, 0, 0, 0.25); */
}

/* .logo_eclinic-blog {
    max-width: 50px;
    position: absolute;
    top: 0;
    left: 0;
    margin-inline-start: 8px;
    margin-block-start: 8px;
} */

.blog-container__img {
    min-width: 180px;
    width: 100%;
    height: auto;
    object-fit:scale-down;
}

.blog-container__title {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--primary-color);
    margin: auto;
    padding: 8px 4px;
}

@media only screen and (min-width: 768px) {
    .blog-container {
        width: 100%;
        padding: 16px;
    }
}
.blog-list-container{
    display: flex;
    justify-content: center;
    margin: 100px auto;
    background-color: white;
}

.blog-slider-container { 
    width: 95%;
    margin: 50px auto;
    overflow: hidden;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.blog-slider-container a {
    text-decoration: none;
}

@media only screen and (min-width: 768px) {
    .blog-list-container {
        flex-direction: row;
        margin-inline: auto;
    }
 
    .blog-slider-container {  
        margin: 100px auto;
        max-width: 1080px;
        width: 95%;
    }
}
.advantages-feature-container {
    display: grid;
    width: 100%;
    grid-template-columns: 10% 90%;
    grid-template-areas: "icon title"
                         ". description";                    
}
.advantages-feature__icon-container {
   display: flex; 
   justify-content: center;
   grid-area: icon;
}

.advantages-feature__icon {
    width: 20px;
    height: 20px;
    align-self: center;
}

.advantages-feature__title {
    color: var(--primary-color);
    font-size: .75rem;
    font-weight: bold;
    grid-area: title;
    margin: 8px;
    text-align: start;
}
.advantages-feature__text {
    font-size: .75rem;
    grid-area: description;
    margin-block: 0px;
    margin-inline: 8px;
    text-align: start;
    width: 100%;
    line-height: var(--branch-line-height);
}

@media only screen and (min-width: 768px) {


    .advantages-feature-container {
        width: 90%;                  
    }
    .advantages-feature__text {
        font-size: .75rem;
        margin-block: 0px;
        margin-inline: 8px;
        text-align: start;
        line-height: var(--branch-line-height);
    }
}
.eclinic-advantages-section-container {
    width: 100%;
    background-color: white;
}

.eclinic-advantages-section {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: auto;
    padding: 36px 0px;
    background-color: white;
}
.eclinic-advantages-section__img-container {  
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.eclinic-advantages-section__img {  
  /*   object-fit: cover;
    max-width: 600px;
    min-width: 300px;
    margin: auto; */
    width: 120%;
}
.eclinic-advantages-section__advages-features {
    width: 80%;
    margin: auto;
}
 
@media only screen and (min-width: 768px) {

    .eclinic-advantages-section {
        display: flex;
        flex-direction: row;
        max-width: 1200px;
    }

    .eclinic-advantages-section__img-container {  
        width:50%;
    }

    .eclinic-advantages-section__img {  
      /*   object-fit: cover; */
      /*   width: 100%;
        max-width: 500px; */
        width: 120%;
    }

    .eclinic-advantages-section__advages-features {
        width: 50%;
    }

}


.product-service-feature__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-block-start: 16px;
}

.product-service-feature__icon {
    width: 17.5px;
    height: 17.5;
    align-self: flex-start;
}

.product-service-feature__entry {
    display: flex;
    flex-direction: row;
    margin-block: 4px;
}

.product-service-feature__text {
    font-size: .75rem;
    color: var(--primary-color);
    margin-inline-start: 8px;
    text-align: start;
}
.product-service__item-container {
    margin-inline: 4px;
    margin-block: 16px;
    display: flex;
    padding: 30px;          
    padding-block: 24px;          /*added*/
    flex-direction: column;
    max-width: 280px;
    min-width: 260px;
    background-color: white;
    opacity: 1;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0px 0.5px 10px rgb( 0, 0, 0, .10);  
    transition: all 250ms ease-in-out;
}


.product-service__item-container:hover {
    transform: scale(1.05);
    box-shadow: 0px 0.5px 20px rgb( 0, 0, 0, .15);  
}

/* .product-service__item-container::after {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .product-service__item-container:hover::after {
    opacity: 1;
  } */

  .solid-divider {
    background-color:  #e8e8e8;
    width: 100%;
    height: 1.5px;
    margin-block-end: 32px;
}

.product-service__item{
    width: 250px;
    display: flex;
    flex-direction: column;
    max-width: 280px;
    min-width:  100px;
    height: auto;
    margin: 5px auto;
    padding: 16px;
}

.product-service__round-container {
    width: 80px;
    margin-inline: auto;
    padding-block-start: 5px;  /*10px*/
    padding-block-end: 20px;   /*30px*/
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.service-container__item-title {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
    margin: auto;
    margin-block-start: 0px;
    margin-block-end: 10px;
}

.service-container__item-description {
    width: 100%;
    font-size: .75rem;
    margin: auto;
    margin-block-start: 0;
    text-align: justify;
    font-weight: 500;
    color: var(--primary-color);
}
.product-service-list-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    margin: auto;
    padding: 48px 16px;
    background-color: var(--branch-gray);
    opacity: 1;
    width: 100%;
}

.product-service-list__title-container {
    width: 100%;
}

.product-service-list__title-container-title {
    width: 100%;
    color: var(--primary-color);
    margin: auto;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
}

.product-service-list__title-container-description {
    width: 90%;
    margin: 30px auto;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.7rem;
    color: var(--primary-color);
    text-align: justify;
}

.product-service-list__title-container-button {
    display: flex;
    justify-content: flex-start;
    padding: 0px 48px;
    margin: 50px auto;
}

.product-service-list__title-container-button-content {
    color: white;
    font-weight: var(--h1-default-weight);
    padding: 10px 15px;
    margin: auto;
    text-decoration: none;
    background-color: var(--branch-opaque-aquamarine);
    border-radius: 5px;
}

.product-service-list__title-container-content {
    object-fit: cover;
    align-self: center;
    width: 100%;
}


@media only screen and (min-width: 768px) {

    .product-service-list__title-container-title {
        width: 80%;
        color: var(--primary-color);
        margin: auto;
        font-weight: bold;
        font-size: 2rem;
        line-height: 3rem;
    }

    .product-service-list-section {
        display: flex;
        flex-wrap: wrap;
        max-width: 1080px;
        justify-content: space-around;
        align-content: center;
        margin: auto;
        padding: 48px 24px;
        background-color: var(--branch-gray);
        opacity: 1;
        width: 90%;
    }

    .product-service-list__title-container-description {
        width: 80%;
        margin: 30px auto;
        font-weight: 400;
        font-size: 1.25rem;
        color: var(--primary-color);
        text-align: center;
    }

    .product-service-list__title-container-button {
        display: flex;
        justify-content: flex-start;
        padding: 0px 55px;
        margin: 50px auto;
    }

    .product-service-list__title-container {
        margin-block-end: 48px;
    }
    
}
.for-who-section {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background-color: var(--branch-gray);
    flex-wrap: wrap;
}

.for-who-section__vertical-divider {
    width: 2px;
    background-color: white;
}

 @media only screen and (min-width: 768px) {
    .for-who-section {
        flex-direction: row;
        padding-inline-start: 48px;
        padding-inline-end: 28px;
        padding-block-start: 24px;
        padding-block-end: 24px;
        width: 100%;
    }
 }
.for-medical-entities-article {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    padding-inline: 8px;
    padding-block: auto;
}

.for-clinic {
    display: flex;
}

.for-consulting-room{
    display: flex;
    align-content: center;
}

.for-clinic__icon {
    width: 60px;
    height: 60px;
    align-self: center; 
    border-radius: 50%;
    background-color: transparent;
}

.for-clinic__icon-img {
    max-width: 60px;
    min-width: 60px;
    width: 100%;
    height: auto;
    object-fit: cover;
    fill-opacity: 1
}

.for-entity_title {
    font-size: .8rem;
    align-self: center;
    font-weight: 500;
    padding-inline: 8px;
    color: var(--primary-color); 
    text-align: start;
}

.for-specialty_title {
    font-size: .8rem;
    align-self: center;
    font-weight: 500;
    padding-inline: 8px;
    color: var(--primary-color); 
    text-align: start;
}

.for-who-section__vertical-divider {
    width: 2px;
    background-color: white;
}

@media only screen and (min-width: 768px) {

    .for-medical-entities-article {
        width:  47.5%;
    }

    .for-entity_title {
        font-size: .9rem;
        line-height: normal;
        align-self: center;
        font-weight: 500;
        padding-inline: 16px;
        color: var(--primary-color); 
    }
    
 }
.for-medical-specialties-article {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.for-medical-specialties-article__title {
    width: 90%;
    margin: auto;
    text-align: center;
    color: var(--primary-color);
    font-size: .9rem;
}

.for-medical-specialties-article__specialties-slider {
    width: 90%;
    margin-inline: auto;
}

 .slick-next:before {
    color: transparent;
}

.slick-prev:before {
    color: transparent;
}

.for-general, .for-specialties {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-inline: 16px;
/*     margin-inline-start: 36px; */
    margin-block: 8px;
}

.for-specialty__icon, .for-specialties__icon {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    align-self: center;
}

.for-general_title, .for-specialties_title {
    text-align: start;
    font-size: .75rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--primary-color);    
    font-weight: normal;
    align-self: center;
    padding-inline-start: 8px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.for-medical-specialties-article__title-container {
    display: flex;
    width: 100%;
    padding-block: 16px;
}

@media only screen and (min-width: 768px) {

    .for-medical-specialties-article {
        width: 47.5%;
        flex-direction: row;
    }

    .for-medical-specialties-article__title-container {
        width: 50%;
    }

    .for-medical-specialties-article__title {
        width: auto;
        text-align: start;
        color: var(--primary-color);
        font-size: .9rem;
        justify-self: center;
    }

    .for-medical-specialties-article__specialties-slider {
        width: 50%;
        align-self: center;
        /* margin-inline-start: 36px; */
    }

    .for-general, .for-specialties {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        margin-inline-start: 36px; 
        margin-block: 8px;
    }
 }
.all-services-icons-container {
    background-color: white;
    margin: auto;
    padding: 48px 16px;
    width: 100%;
    overflow: hidden;
}
.service-section__container {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.service-section__icon-container {

}

.service-section__icon {
    width: 100%;
    max-width: 70px;
    min-width: 40px;
    object-fit: cover;
    margin: auto;
}

.service-section__title {
    color: var(--primary-color);
    font-size: 0.75rem;
    text-align: center;
    width: 90%;
    margin: auto;
}
#datacrm-wpp-XBNbSYA4GHT4f15Q3vgw5w { 
    right: 50px;
    bottom: 20vh;
  }
.hubspot-form-drhelp {
    width: 100%;
    margin:auto;
    background-color: white;
    padding: 32px 16px;
}

.hubspot-form-container {
    width: 70%;
    margin: auto;
}
.price-section-container{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-block: 24px;
    background-color: white;
}

.price-section-container__title {
    font-size: 2rem;
    margin: auto;
    color: var(--primary-color);
    padding-block-start: 28px;
    padding-block-end: 20px;
}

.price-section__button-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin: auto;
    padding-block: 20px;
}

.schedule-demo__monthly-button, .schedule-demo__annual-button{
    border-radius: 8px;
    cursor: pointer;
    font-size: .9rem;
    font-weight: bold;
    padding: 8px 24px;
    width: 120px;
    display: flex;
    align-content: center;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-inline: 8px;
}

.schedule-demo__monthly-button {
      background-color: white;
      border: 1px solid var(--branch-light-aquamarine);
      color: var(--branch-light-aquamarine);
}

.schedule-demo__annual-button  {
    background-color: var(--branch-light-aquamarine);
    color: white;
    border: 1px solid var(--branch-light-aquamarine);
}

.price-section__consulting-room-plan, .price-section__clinic-plan {
    background-color: var(--primary-color);
    border: 1px solid var(--branch-light-aquamarine);
    height: 100%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 220px;
}

.dummy-separator {
    background-color: var(--branch-gray);
    width: 90%;
    height: 1px;
    margin-block: 16px;
}

.meetings-iframe-container{
    width: 100%;
    height: 80vh;
}

.price-section__plans-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-block: 24px;
    margin-inline: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.price-section__payment-button-container {
    padding: 8px 16px;
    display: flex;
    margin-block-end: 5px;
}

.price-section__payment-button-container a {
    width: 100%;
    text-decoration: none;
}

.price-section__payment-button-text {
    align-self: center;
    margin-block: 0;
    margin-inline: auto;
    font-size: .95rem;
    font-weight: bold;
}

.price-section__consulting-room-plan-container, .price-section__clinic-plan-container {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: space-between;
    margin-inline: auto;
    margin-block: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: var(--branch-gray);
}

.price-section__consulting-room-plan-img-container, .price-section__clinic-plan-img-container {
    margin-block-start: 16px;
}

.price-section__consulting-room-plan-img, .price-section__clinic-plan-img {
    width: 60px;
    object-fit: cover;
    height: 60px;
}

.price-section__consulting-room-plan h3 {
    font-size: 1.75rem;
    color: var(--branch-light-aquamarine);
    margin-block: 4px;
    margin-inline: auto;
}

.price-section__consulting-room-plan-for {
    font-size: .75rem;
    color: var(--branch-smooth-blue);
    margin-block: 0px;
    margin-inline: auto;
    line-height: .75rem;
}

.price-section__consulting-room-plan-price-text-container {
    display: flex;
    flex-direction: row;
    margin-block: 16px;
    margin-inline: auto;
}

.price-section__consulting-room-plan-price-text-for {
    display: flex;
    flex-direction: column;
}

.price-section__consulting-room-plan-price-text-for p {
   margin-block: 0px;
}

.price-section__consulting-room-plan-price-text, .price-section__clinic-custom-plan {
    font-size: 1.2rem;
    color: white;
    margin-block-start: 16px;
    margin-block-end: 2px;
    font-weight: bold;
}

.price-section__consulting-room-plan-price-text {
    margin-block: 0;
    font-size: 1.75rem;
    line-height: 1.75rem;
    align-self: flex-end;
}

.price-section__clinic-custom-plan-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.price-section__clinic-custom-plan {
    align-self:stretch;
    justify-self: center;
    margin-inline: auto;
    margin-block: auto;
}

.price-section__consulting-room-plan-by {
    font-size: .70rem;
    color: white;
    margin-block: 0px;
    margin-inline-start: 8px;
    line-height: 1.2rem;
    margin-block-end: 16px;
}

.price-section__consulting-room-plan-price-locale {
    margin-block: 0px;
    margin-inline-start: 8px; 
    color: white;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: start;
}

.price-section__payment-button {
    background-color: var(--branch-light-aquamarine);
    border-radius: 8px;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    margin-inline: auto;
    display: flex;
    width: 100%;
    height: 50px;
}

.price-section__payment-epayco-button {
    width: 100%;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
}

.price-section__payment-epayco-button p{
    z-index: 10;
    position: absolute;
    align-self: center;
    color: white;
    margin: auto;
    font-weight: bold;
    cursor: pointer;
}

.price-section__consulting-room-plan-features-container, .price-section__clinic-custom-plan-features-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-block: 16px;
    margin: 0;
}

.more-questions-view-in-price-container {
    padding-block: 48px;
}

@media only screen and (min-width: 768px) {

    .price-section__plans-container {
        flex-direction: row;
        margin-block-end: 56px;
    }

    .price-section__consulting-room-plan-container, .price-section__clinic-plan-container {
        width: 380px;
        margin-inline: 16px;
    }

    .web-page-view-in-price-container {
        padding-block: 48px;
    }


    .frequent_questions-view-in-price-container {
        background-color: var(--branch-gray);
        width: 100%;
        padding-block: 48px;
    }
}
.drop-list-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--branch-gray);
    cursor: pointer;
    padding-inline-start: 16px;
}

.drhelp-drop-list__title {
    font-size: .95rem;
    width: 80%;
    color: var(--primary-color);
    text-align: start;
}

.drop-list-item__icon {
    margin: 8px;
    width: 20%;
    align-self: center;
}

.drhelp-drop {
    overflow: hidden;
}

.drhelp-drop-list__container {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.drhelp-drop-enter{
   height: 0px;
}

.drhelp-drop-list__generated-container {
    background-color: white;
    padding-inline: 0;
    padding-block: 8px;
}

.drhelp-drop-enter-active{
    height: 400px;
    transition: all 500ms ease;
}

.drhelp-drop-enter-active-done{
   
}

.drhelp-drop-exit {
}
 
 .drhelp-drop-exit-active {
     height: 0px;
     transition: all 500ms ease;
 }
.check-item-list__container {
    display: flex;
    padding-inline-start: 56px;
    padding-inline-end: 16px;
    background-color: white;
    width: 100%;
/*     height: 40px; */

}
.check-item-list__icon-container {
    margin-inline-end: 8px;
    height: 1.25rem;
    vertical-align: middle;
    margin-block-start: .2rem;
}

.check-item-list__icon {
    align-self: center;
    margin-inline: 2px;
    margin-block: 2px;
  
}

.check-item-list__container p {
    font-size: .8rem;
    margin-block: 1px;
    font-weight: 500;
    text-align: start;
    line-height: 1.5rem;
}



.web-page-own-domain-advise-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    margin-inline: auto;
    margin-block: 16px;
    padding-inline: 16px;
    padding-block: 12px;
    box-shadow: 0px 0.5px 10px rgb( 0, 0, 0, .33);  
    border-radius: 8px;
}

.web-page-own-domain-advise-container__advertisement {
    display: flex;
    flex-direction: row;
}

.web-page-own-domain-advise-container__advertisement-icon-container {
    display: flex;
    align-self: center;
    margin-inline: 8px;
    margin-block: 16px;
}

.web-page-own-domain-advise-container__advertisement-icon {
   min-width: 60px;
   height: auto;
   max-width: 80px;

}

.web-page-own-domain-advise-container__title {
    color: var(--primary-color);
    font-size: 1.5rem;
    text-align: start;
    line-height: 1.8rem;
    margin-block: 8px;
}

.web-page-own-domain-advise-container__text {
    margin-block: 4px;
    font-size: .85rem;
    color: #808080;
    text-align: start;
}

.web-page-own-domain-advise-container__info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8px;
}

.web-page-own-domain-to_expert-button {
    background-color: var(--branch-light-aquamarine);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    font-size: 1rem;
    color: white;
    padding: 12px 32px;
    border: 1px solid var(--branch-light-aquamarine);
    align-self: center;
    justify-self: center;
    cursor: pointer;
}

.web-page-own-domain-to_expert-button-conatainer {
    display: flex;
    padding-inline: 32px;
    padding-block: 16px;
}

@media only screen and (min-width: 768px){
    .web-page-own-domain-advise-container {
        flex-direction: row;
        margin-block: 32px;
    }

    .web-page-own-domain-advise-container__title {
        font-size: 1.25rem;
        text-align: start;
        line-height: 1.5rem;
        margin-block: 8px;
    }
    
    .web-page-own-domain-advise-container__text {
        margin-block: 4px;
        font-size: .9rem;
        color: #808080;
        text-align: start;
    }

    .web-page-own-domain-advise-container__advertisement-icon-container {
        display: flex;
        align-self: center;
        margin-inline: 16px;
        margin-block: 16px;
    }
    
}


.frequent-question-section {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px;
}

.frequent-question-section__title {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin: 24px auto;
}

.frequent-question-section__questions {
    display: flex;
    flex-direction: column;
}


@media only screen and (min-width: 768px) {

    .frequent-question-section {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 36px;
    }

    .frequent-question-section__title {
        color: var(--primary-color);
        font-size: 1.75rem;
        margin: 36px auto;
    }
}


.question-container {
    background-color: white;
    box-shadow: 0 2.5px 0.5px 1px rgba(125, 125, 125, 0.07);
    margin-block: 8px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-inline: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 8px;
}

.question__question {
    font-size: .8rem;
    margin-inline: 8px;
    cursor: pointer;
    text-align: start;
}

.question-container_visible-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.question__icon-container {
    width: 50px;
    height: 50px;
}

.question-container_answer-container {
    width: 90%;
    overflow: hidden;
    margin: auto;
}

.question-container_answer-container p {
    font-size: 0.75rem;
    text-align: start;
    padding-block: 16px;

}

.question_anim {
    overflow: hidden;
}

.question_anim-enter{
    height: 0px;
 }

 .question_anim-enter-active{
     height: 125px;
    transition: all 500ms ease;
}

.question_anim-exit-active {
    height: 0px;
    transition: all 500ms ease;
}

.question_anim-enter-active-done{
    
}


@media only screen and (min-width: 768px) {
    .question-container {
        box-shadow: 0 2.5px 0.5px 1px rgba(125, 125, 125, 0.07);
        margin-block: 8px;
        width: 75%;
        margin-inline: auto;
        padding: 16px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .question__question {
        font-size: 1.0rem;
        margin-inline: 16px;
    }

    .question-container_answer-container p {
        font-size: 0.9rem;
        text-align: start;
        padding-block: 16px;
    
    }

}
.more-questions-section {
    display: flex;
    flex-direction: column;
}

.more-questions-section__title {
    margin: auto;
}

.more-questions-section__title-text {
    font-size: 1.75rem;
    line-height: 2rem;
    color: var(--primary-color);
}

.more-questions-section__subtitle {
    font-size: 1rem;
    color: #808080;
}

.more-questions-section__features-expert {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-inline: auto;
    box-shadow: 0px 0.5px 10px rgb( 0, 0, 0, .30); 
    padding-inline: 24px; 
    padding-block: 0;
}

.more-questions-section__features {
    padding: 32px;
}

.more-questions-section__to-expert-button {
    display: flex;
    padding: 32px;
}

@media only screen and (min-width: 768px) {
    .more-questions-section__features-expert {
        flex-direction: row;
    }
}
.review-in-price-section-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--branch-light-aquamarine);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    justify-content: center;
}

.review-in-price-review-container {
    display: flex;
    min-width: 260px;
    max-width: 500px;
    background-color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-block: 8px;
    border-radius: 8px;
    margin-inline: auto;
    justify-self: center;
}


.review-in-price-review__review-text {
    font-style: oblique;
    margin: 8px auto;
    font-size: 0.75rem;
    text-align: justify;
    width: 75%;
    align-self: center;
}

.review-in-price-review__img {
    width: 50px;
    height: 50px;
  /*   background-color: var(--branch-light-aquamarine); */
    border-radius: 50%;
    align-self: center;
    margin: 8px;
    margin-inline: 16px;
}

.review-in-price-review__doctor-name {
    text-transform: uppercase;
}

.is-feature-included {
    color: red;
}

.is-feature-included.parent {
    height: 200px;
}


@media only screen and (min-width: 768px){

    .review-in-price-section-container {
        flex-direction: row;
        justify-content: center;
        align-content: stretch;
        padding-block: 16px;
    }

    .review-in-price-review-container {
        height: auto;
        margin-inline: 16px;
    }
}
.epayco-button-container {
  /*   width: fit-content;
    padding-inline: 24px;
    height: 50px;
    background-color: var(--branch-light-aquamarine);
    border-radius: 16px;
    color: white;
    font-size: 1rem;
    display: flex;
    align-content: center; */
    cursor: pointer;
    padding-inline: 16px;
/*     position: absolute;
    top: 0;
    left: 0; */
    padding-block: 8px;
}

.epayco-button-container img {
    width: 100%;
    height: 50px;
   /*  position: absolute;
    top: 0;
    left: 0;
    z-index: 1; */
}
.epayco-button-container button {
    z-index: 1;
}

.epayco-button-container p {
    z-index: 10;
    margin: 0;
    
}
.footer-container {
    text-align: center;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 16px;
    width: 100%;
    flex-wrap: wrap;
  }

  .footer-logo-container{
    display:flex;
    flex-direction: column;
    margin-block-end: 20px;
  }

  .footer-logo-container p {
    font-size: .75rem;
    margin-inline: auto;
    margin-block-start: 0px;
    margin-block-end: 8px;
  }
 

.eclinic-footer {
  background-color: var(--primary-color);
  padding: 16px 0;
  width: 100%;
}

  .footer-logo__image-wrapper {
    display: flex;
    justify-items: center;
    margin-block: 0;
  }

  .footer-logo__image-wrapper a {
    margin: auto;
  }

  .footer__logo {
  
    width: 100%;
    min-width: 150px;
    max-width: 220px;
    height: auto;
    justify-self: center;
}

.footer_nav-companies-entries, .footer_nav-modules-entries {
  color: white;
  list-style: none;
  text-align: start;
}

.footer_nav-companies-entries li, .footer_nav-modules-entries li {
  
  font-size: .9rem;
  margin-block: 2px;
}

.footer_entry {
  color: white;
  text-decoration: none;
}

.footer_entry:visited {
  text-decoration: none;
  color: white;
}

.eclinic-footer p {
 color: white;
/*  font-size: .9rem; */
 text-align: center;
}

.footer-logo__icons-wrapper {
  display: flex;
  justify-content: center;
  margin-block: 4px;
}

.footer-logo__icons-circular {
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  margin-inline: 8px;
}

.footer-logo__icons-wrapper a {
  margin-inline: 8px;
  align-self: center;
  justify-self: center;
  display: flex;
}

.footer-logo__icons-wrapper a svg {
  align-self: center;
  justify-self: center;
}

.footer_nav__companies-container, .footer_nav__modudes-container {
  width: 100%;
}

.footer_nav__companies-container h3, .footer_nav__modules-container h3 {
  width: 100%;
  text-align: start;
  color: white;
  font-size: 1rem;
  padding-inline-start: 40px;
}

.copyRigth {
  color: white;
  font-size: .75rem;
  margin: 16px 8px;

}

@media only screen and (min-width: 768px){
  .footer-container {
    padding: 32px 30px;
    flex-direction: row;
  }


  .footer-logo-container, .footer_nav__companies-container, .footer_nav__modules-container {
    width:33%;
  }

.eclinic-footer {
  background-color: var(--primary-color);
  padding: 16px 0;
}

  .footer-logo__image-wrapper {
    display: flex;
    justify-items: start;
  }

  .footer__logo {
    min-width: 150px;
    max-width: 250px;
    height: auto;
}

.footer_nav-entries {
  color: white;
  list-style: none;
  text-align: start;
}

.footer_nav-entries li{
  
  font-size: 1.0rem;
}

.footer_entry {
  color: white;
  text-decoration: none;
}

.footer_entry:visited {
  text-decoration: none;
  color: white;
}

.eclinic-footer p {
    color: white;
    font-size: .9rem;
    text-align: center;
    margin: auto;
  }

.footer-divider {
  background-color: white;
  height: 1px;
  margin: 24px 5%;
}

.copyRigth {
  margin: 20px 8px;
}


}
.blog-article-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    border: 8px;
    margin: 24px auto;
    box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.2);
    border-bottom: .25px solid rgba(124, 124, 124, 0.2);
    max-width: 720px;
}

.blog-article-section__title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--primary-color);
    width: 90%;
    margin-inline: auto;
}

.blog-article-section__publish-date {
    font-size: 0.75rem;
    color: #ababab;
    width: 100%;
    margin-inline: auto; 
}

.blog-article-section__img-container {
    min-width: 280px;
    width: 100%;
    height: auto;
    position: relative;
}

.blog-article-section__img-cover {
    background-color: black;
    opacity: 0.25;
    z-index: 5;
    width: 100%;
    height: 100%;
    position: absolute;
}

.blog-article-section__img {
    min-width: 280px;
    width: 100%;
    height: auto;
    object-fit: cover;
 /*    max-height: 320px; */
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.blog-article-section__description {
    font-size: .9rem;
    text-align: justify;
    color: #878787;
}

.blog-article-section__continue-reading {
    background-color: white;
    padding-inline: 16px;
    padding-block: 8px;
    border: 2px solid var(--primary-color);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-inline: auto;
    border-radius: 4px;
    margin-block: 24px;
}

.blog-article-section__continue-reading a, .blog-article-section__continue-reading a:visited {
  text-decoration: none;
  color: var(--primary-color); 
}

.blog-article-section__continue-reading:hover {
    background-color: var(--primary-color); 
    color: white;
}

.blog-article-section__continue-reading:hover a {
    color: white;
}


.blog-article-section__continue-reading:hover a:visited{
    color: white;
}


@media only screen and (min-width: 768px) {

    .blog-article-section__title {
        font-size: 1.5rem;
        line-height: 2rem;
        width: 90%;
        margin-bottom: 8px;

    }

    .blog-article-section {
        margin: 36px auto;
    }

    .blog-article-section__continue-reading {
        padding-block: 4px;
        font-size: .75rem;
    }

    .blog-article-section__publish-date {
        color: #ababab;
        width: 100%;
        margin-inline: auto; 
        margin-block: 20px;
    }
    
}
.blog-article-section h3 {
    color: var(--primary-color);
    text-align: start;
    margin-block-start: 16px;
    margin-block-end: 0px;
    font-size: 1.5rem;
    font-weight: bold;
}
.talk-to-expert-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-block: 24px;
}
.meetings-iframe-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.talk-to-expert__back_button {
    color: white;
    background-color: var(--branch-light-aquamarine);
    border-radius: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 50px;
    margin: auto;
    display: flex;
    padding-inline: 48px;
    margin-block-end: 36px;
    margin-block-start: 0;
    cursor: pointer;
}

.talk-to-expert__back_button p {
    align-self: center;
}

.talk-to-expert__back_button-link {
    text-decoration: none;
}
/* 

G-HV86MG1D9D
G-HV86MG1D9D
G-HV86MG1D9D */
.epayco-response-section {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    flex-direction: column;
}

.epayco-response__store-icon-container {
    align-self: center;
    justify-self: center;
}

.epayco-response__store-icon {
    align-self: center;
    justify-self: center;
}

.epayco-response__thanks-text {
    font-size: 1.5rem;
    color: var(--primary-color);
    align-self: center;
}

.epayco-response__back-button {
    background-color: var(--branch-light-aquamarine);
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 45px;
    padding-inline: 48px;
    margin-inline: auto;
    margin-block-start: 16px;
    cursor: pointer;
    display: flex;
}

.epayco-response__back-button p { 
    align-self: center;
}

.epayco-response__back-button-link {
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-inline: auto;
}
.banner-container{
    width: 90%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 20%);
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: fixed;
    bottom: 0;
    left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: auto!important;
    padding-block: 4px;
}

.banner-cookie__text {
    color: white;
    font-size: .9rem;
    margin: auto;
    margin-block-end: 0;
    margin-block-start: 0;
}

.banner-cookie__privacy-policies {
    color: white;
    font-size: .9rem;
    font-weight: bold;
    margin: 0;
}


.privacy-policies__link {
    color: white;
    text-decoration: none;
    margin: 0;
}

.banner-cookie__privacy-policies-button-close {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-inline: 16px;
    margin-block-start: 4px;
    cursor: pointer;
}

.cookie-banner_accept-button {
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.515);
}

.cookie-banner_accept-button p {
    margin-block: 0;
    padding-inline: 16px;
    padding-block: 6px;
    overflow: hidden;
}
.cookie-policy__text-content {
    font-size: 1rem;
    text-align: justify;
    line-height: 1.75rem;
    max-width: 1080px;
    margin: auto;
}

.cookie-policy-title h1 {
    
}

.cookie-policy-title {
    padding-block-start: 36px;
    padding-block-end: 24px;
}
