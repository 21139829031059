.epayco-button-container {
  /*   width: fit-content;
    padding-inline: 24px;
    height: 50px;
    background-color: var(--branch-light-aquamarine);
    border-radius: 16px;
    color: white;
    font-size: 1rem;
    display: flex;
    align-content: center; */
    cursor: pointer;
    padding-inline: 16px;
/*     position: absolute;
    top: 0;
    left: 0; */
    padding-block: 8px;
}

.epayco-button-container img {
    width: 100%;
    height: 50px;
   /*  position: absolute;
    top: 0;
    left: 0;
    z-index: 1; */
}
.epayco-button-container button {
    z-index: 1;
}

.epayco-button-container p {
    z-index: 10;
    margin: 0;
    
}