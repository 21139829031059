.advantages-feature-container {
    display: grid;
    width: 100%;
    grid-template-columns: 10% 90%;
    grid-template-areas: "icon title"
                         ". description";                    
}
.advantages-feature__icon-container {
   display: flex; 
   justify-content: center;
   grid-area: icon;
}

.advantages-feature__icon {
    width: 20px;
    height: 20px;
    align-self: center;
}

.advantages-feature__title {
    color: var(--primary-color);
    font-size: .75rem;
    font-weight: bold;
    grid-area: title;
    margin: 8px;
    text-align: start;
}
.advantages-feature__text {
    font-size: .75rem;
    grid-area: description;
    margin-block: 0px;
    margin-inline: 8px;
    text-align: start;
    width: 100%;
    line-height: var(--branch-line-height);
}

@media only screen and (min-width: 768px) {


    .advantages-feature-container {
        width: 90%;                  
    }
    .advantages-feature__text {
        font-size: .75rem;
        margin-block: 0px;
        margin-inline: 8px;
        text-align: start;
        line-height: var(--branch-line-height);
    }
}