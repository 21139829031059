.to_expert-button {
    background-color: var(--branch-light-aquamarine);
    width: fit-content;
    border-radius: 8px;
    font-size: 1rem;
    color: white;
    font-weight: bold;
    padding: 12px 32px;
    border: 1px solid var(--branch-light-aquamarine);
    cursor: pointer;
    align-self: center;
    justify-self: center;
}

.to-expert-button-link {
    height: fit-content;
    margin-block: auto;
} 

