.check-item-list__container {
    display: flex;
    padding-inline-start: 56px;
    padding-inline-end: 16px;
    background-color: white;
    width: 100%;
/*     height: 40px; */

}
.check-item-list__icon-container {
    margin-inline-end: 8px;
    height: 1.25rem;
    vertical-align: middle;
    margin-block-start: .2rem;
}

.check-item-list__icon {
    align-self: center;
    margin-inline: 2px;
    margin-block: 2px;
  
}

.check-item-list__container p {
    font-size: .8rem;
    margin-block: 1px;
    font-weight: 500;
    text-align: start;
    line-height: 1.5rem;
}


