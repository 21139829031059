.blog-list-container{
    display: flex;
    justify-content: center;
    margin: 100px auto;
    background-color: white;
}

.blog-slider-container { 
    width: 95%;
    margin: 50px auto;
    overflow: hidden;
    height: fit-content;
}
.blog-slider-container a {
    text-decoration: none;
}

@media only screen and (min-width: 768px) {
    .blog-list-container {
        flex-direction: row;
        margin-inline: auto;
    }
 
    .blog-slider-container {  
        margin: 100px auto;
        max-width: 1080px;
        width: 95%;
    }
}