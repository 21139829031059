.price-section-container{
    height: fit-content;
    padding-block: 24px;
    background-color: white;
}

.price-section-container__title {
    font-size: 2rem;
    margin: auto;
    color: var(--primary-color);
    padding-block-start: 28px;
    padding-block-end: 20px;
}

.price-section__button-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin: auto;
    padding-block: 20px;
}

.schedule-demo__monthly-button, .schedule-demo__annual-button{
    border-radius: 8px;
    cursor: pointer;
    font-size: .9rem;
    font-weight: bold;
    padding: 8px 24px;
    width: 120px;
    display: flex;
    align-content: center;
    justify-content: center;
    height: fit-content;
    margin-inline: 8px;
}

.schedule-demo__monthly-button {
      background-color: white;
      border: 1px solid var(--branch-light-aquamarine);
      color: var(--branch-light-aquamarine);
}

.schedule-demo__annual-button  {
    background-color: var(--branch-light-aquamarine);
    color: white;
    border: 1px solid var(--branch-light-aquamarine);
}

.price-section__consulting-room-plan, .price-section__clinic-plan {
    background-color: var(--primary-color);
    border: 1px solid var(--branch-light-aquamarine);
    height: 100%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 220px;
}

.dummy-separator {
    background-color: var(--branch-gray);
    width: 90%;
    height: 1px;
    margin-block: 16px;
}

.meetings-iframe-container{
    width: 100%;
    height: 80vh;
}

.price-section__plans-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-block: 24px;
    margin-inline: auto;
    height: fit-content;
}

.price-section__payment-button-container {
    padding: 8px 16px;
    display: flex;
    margin-block-end: 5px;
}

.price-section__payment-button-container a {
    width: 100%;
    text-decoration: none;
}

.price-section__payment-button-text {
    align-self: center;
    margin-block: 0;
    margin-inline: auto;
    font-size: .95rem;
    font-weight: bold;
}

.price-section__consulting-room-plan-container, .price-section__clinic-plan-container {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: fit-content;
    justify-content: space-between;
    margin-inline: auto;
    margin-block: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: var(--branch-gray);
}

.price-section__consulting-room-plan-img-container, .price-section__clinic-plan-img-container {
    margin-block-start: 16px;
}

.price-section__consulting-room-plan-img, .price-section__clinic-plan-img {
    width: 60px;
    object-fit: cover;
    height: 60px;
}

.price-section__consulting-room-plan h3 {
    font-size: 1.75rem;
    color: var(--branch-light-aquamarine);
    margin-block: 4px;
    margin-inline: auto;
}

.price-section__consulting-room-plan-for {
    font-size: .75rem;
    color: var(--branch-smooth-blue);
    margin-block: 0px;
    margin-inline: auto;
    line-height: .75rem;
}

.price-section__consulting-room-plan-price-text-container {
    display: flex;
    flex-direction: row;
    margin-block: 16px;
    margin-inline: auto;
}

.price-section__consulting-room-plan-price-text-for {
    display: flex;
    flex-direction: column;
}

.price-section__consulting-room-plan-price-text-for p {
   margin-block: 0px;
}

.price-section__consulting-room-plan-price-text, .price-section__clinic-custom-plan {
    font-size: 1.2rem;
    color: white;
    margin-block-start: 16px;
    margin-block-end: 2px;
    font-weight: bold;
}

.price-section__consulting-room-plan-price-text {
    margin-block: 0;
    font-size: 1.75rem;
    line-height: 1.75rem;
    align-self: flex-end;
}

.price-section__clinic-custom-plan-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.price-section__clinic-custom-plan {
    align-self:stretch;
    justify-self: center;
    margin-inline: auto;
    margin-block: auto;
}

.price-section__consulting-room-plan-by {
    font-size: .70rem;
    color: white;
    margin-block: 0px;
    margin-inline-start: 8px;
    line-height: 1.2rem;
    margin-block-end: 16px;
}

.price-section__consulting-room-plan-price-locale {
    margin-block: 0px;
    margin-inline-start: 8px; 
    color: white;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: start;
}

.price-section__payment-button {
    background-color: var(--branch-light-aquamarine);
    border-radius: 8px;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    margin-inline: auto;
    display: flex;
    width: 100%;
    height: 50px;
}

.price-section__payment-epayco-button {
    width: 100%;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
}

.price-section__payment-epayco-button p{
    z-index: 10;
    position: absolute;
    align-self: center;
    color: white;
    margin: auto;
    font-weight: bold;
    cursor: pointer;
}

.price-section__consulting-room-plan-features-container, .price-section__clinic-custom-plan-features-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-block: 16px;
    margin: 0;
}

.more-questions-view-in-price-container {
    padding-block: 48px;
}

@media only screen and (min-width: 768px) {

    .price-section__plans-container {
        flex-direction: row;
        margin-block-end: 56px;
    }

    .price-section__consulting-room-plan-container, .price-section__clinic-plan-container {
        width: 380px;
        margin-inline: 16px;
    }

    .web-page-view-in-price-container {
        padding-block: 48px;
    }


    .frequent_questions-view-in-price-container {
        background-color: var(--branch-gray);
        width: 100%;
        padding-block: 48px;
    }
}