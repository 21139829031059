.epayco-response-section {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    flex-direction: column;
}

.epayco-response__store-icon-container {
    align-self: center;
    justify-self: center;
}

.epayco-response__store-icon {
    align-self: center;
    justify-self: center;
}

.epayco-response__thanks-text {
    font-size: 1.5rem;
    color: var(--primary-color);
    align-self: center;
}

.epayco-response__back-button {
    background-color: var(--branch-light-aquamarine);
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    width: fit-content;
    height: 45px;
    padding-inline: 48px;
    margin-inline: auto;
    margin-block-start: 16px;
    cursor: pointer;
    display: flex;
}

.epayco-response__back-button p { 
    align-self: center;
}

.epayco-response__back-button-link {
  text-decoration: none;
  width: fit-content;
  height: fit-content;
  margin-inline: auto;
}