.drop-list-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--branch-gray);
    cursor: pointer;
    padding-inline-start: 16px;
}

.drhelp-drop-list__title {
    font-size: .95rem;
    width: 80%;
    color: var(--primary-color);
    text-align: start;
}

.drop-list-item__icon {
    margin: 8px;
    width: 20%;
    align-self: center;
}

.drhelp-drop {
    overflow: hidden;
}

.drhelp-drop-list__container {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.drhelp-drop-enter{
   height: 0px;
}

.drhelp-drop-list__generated-container {
    background-color: white;
    padding-inline: 0;
    padding-block: 8px;
}

.drhelp-drop-enter-active{
    height: 400px;
    transition: all 500ms ease;
}

.drhelp-drop-enter-active-done{
   
}

.drhelp-drop-exit {
}
 
 .drhelp-drop-exit-active {
     height: 0px;
     transition: all 500ms ease;
 }