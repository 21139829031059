.header-container {
  text-align: center;
  background-color:white;
  display: flex;
  align-content: center;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
  flex-wrap: wrap;
}

.header-logo-container {
  padding: 24px 0;
  width: 66%;
  margin: auto;
}

.header-logo-container__img {
    min-width: 120px;
    max-width: 180px;
}

.header__menu {
  display: flex;
  width: 33%;
  padding-inline: 32px;
}

/* 
.header__menu svg {
  align-self: center;
  justify-self: end;
} */

.header__menu-navbar {
  opacity: 0;
  position: fixed;
  padding: 10px 10px;
  left: 100%;
  transform: translateX(-110%);
  background-color: white;
  list-style: none;
  text-align: end;
  z-index: 5;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, .8);
  border-radius: 7px;
  transition: opacity .1s ease;
}

/* .header__menu-navbar a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 0.9rem;
  line-height: 1.5rem;
} */

.header__menu-navbar a:hover {
  color: var(--branch-light-aquamarine);
}


.p-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .black-background_overlay {
  z-index: 3;
  opacity: 0;
  transition: opacity .5s ease;
  background-color: rgba(0, 0, 0, .5);
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
} */



@media only screen and (min-width: 768px) {

  .header-container {
    text-align: center;
    background-color:white;
    display: flex;
    align-content: center;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
    flex-wrap: wrap;
  }

  .header__menu-navbar {
    opacity: 1;
    display: inline-block;
    position: absolute;
    list-style-type: none;
    padding: 10px 10px;
    left: 100%;
    background-color: var(--ba);
    text-align: end;
    box-shadow: none;
    border-radius: 7px;
    transition: none;
    font-weight: bold;
  }

  .header-logo-container {
    display: flex;
    justify-content: flex-start;
    width: 30%;
    padding-inline-end: 0%;
    padding-inline-start: 2.5%;
  }

  .header__menu {
    width: 70%;
    justify-content: flex-end;
    padding-inline-end: 2.5%;
    padding-inline-start: 0%;
  }

 /*  .black-background_overlay {
    display: block;
    opacity: 1.0;
  } */

}


@media only screen and (min-width: 1024px) {

  .header-logo-container {
    display: flex;
    width: 25%;
    padding-inline-end: 0%;
    padding-inline-start: 10%;
  }

  .header__menu {
    display: flex;
    width: 75%;
    justify-content: flex-end;
    padding-inline-end: 10%;
    padding-inline-start: 0%;
  }
  
}

