.for-medical-specialties-article {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.for-medical-specialties-article__title {
    width: 90%;
    margin: auto;
    text-align: center;
    color: var(--primary-color);
    font-size: .9rem;
}

.for-medical-specialties-article__specialties-slider {
    width: 90%;
    margin-inline: auto;
}

 .slick-next:before {
    color: transparent;
}

.slick-prev:before {
    color: transparent;
}

.for-general, .for-specialties {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-inline: 16px;
/*     margin-inline-start: 36px; */
    margin-block: 8px;
}

.for-specialty__icon, .for-specialties__icon {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    align-self: center;
}

.for-general_title, .for-specialties_title {
    text-align: start;
    font-size: .75rem;
    width: fit-content;
    color: var(--primary-color);    
    font-weight: normal;
    align-self: center;
    padding-inline-start: 8px;
    height: fit-content;
}

.for-medical-specialties-article__title-container {
    display: flex;
    width: 100%;
    padding-block: 16px;
}

@media only screen and (min-width: 768px) {

    .for-medical-specialties-article {
        width: 47.5%;
        flex-direction: row;
    }

    .for-medical-specialties-article__title-container {
        width: 50%;
    }

    .for-medical-specialties-article__title {
        width: auto;
        text-align: start;
        color: var(--primary-color);
        font-size: .9rem;
        justify-self: center;
    }

    .for-medical-specialties-article__specialties-slider {
        width: 50%;
        align-self: center;
        /* margin-inline-start: 36px; */
    }

    .for-general, .for-specialties {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        margin-inline-start: 36px; 
        margin-block: 8px;
    }
 }