.cookie-policy__text-content {
    font-size: 1rem;
    text-align: justify;
    line-height: 1.75rem;
    max-width: 1080px;
    margin: auto;
}

.cookie-policy-title h1 {
    
}

.cookie-policy-title {
    padding-block-start: 36px;
    padding-block-end: 24px;
}