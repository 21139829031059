.product-service__item-container {
    margin-inline: 4px;
    margin-block: 16px;
    display: flex;
    padding: 30px;          
    padding-block: 24px;          /*added*/
    flex-direction: column;
    max-width: 280px;
    min-width: 260px;
    background-color: white;
    opacity: 1;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0px 0.5px 10px rgb( 0, 0, 0, .10);  
    transition: all 250ms ease-in-out;
}


.product-service__item-container:hover {
    transform: scale(1.05);
    box-shadow: 0px 0.5px 20px rgb( 0, 0, 0, .15);  
}

/* .product-service__item-container::after {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .product-service__item-container:hover::after {
    opacity: 1;
  } */

  .solid-divider {
    background-color:  #e8e8e8;
    width: 100%;
    height: 1.5px;
    margin-block-end: 32px;
}

.product-service__item{
    width: 250px;
    display: flex;
    flex-direction: column;
    max-width: 280px;
    min-width:  100px;
    height: auto;
    margin: 5px auto;
    padding: 16px;
}

.product-service__round-container {
    width: 80px;
    margin-inline: auto;
    padding-block-start: 5px;  /*10px*/
    padding-block-end: 20px;   /*30px*/
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.service-container__item-title {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
    margin: auto;
    margin-block-start: 0px;
    margin-block-end: 10px;
}

.service-container__item-description {
    width: 100%;
    font-size: .75rem;
    margin: auto;
    margin-block-start: 0;
    text-align: justify;
    font-weight: 500;
    color: var(--primary-color);
}