.customer-review {
    width: 90%;
    display: flex;
    flex-direction: column;
    min-width: 220px;
    max-width: 320px;
    align-self: center;
    margin: 0px auto;
    padding-top: 16px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: white;
}

.customer-review__item-container {
    display: flex;
    align-content: space-between;
    min-width: 200px;
    max-width: 350px;
    margin-block: 16px;
}

.customer-review__footer {
    width: 100%;
    height: 75px;
    padding: 10px;
    background-color: var(--branch-smooth-blue);
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
}

.customer-review__footer p {
    margin: auto;
}

.customer-review__text {
    font-style: oblique;
    width: 90%;
    height: 110px;
    margin: 8px auto;
    font-size: 0.75rem;
    text-align: center;
}

.customer-review__footer-name {
    color: var(--primary-color);
    font-weight: 500;
    font-size: .9rem;
    margin: auto;
    padding: 0;
    text-align: center;
}

.customer-review__footer-specialty {
    padding: 0;
    margin: 0;
    font-size: .8rem;
    color: var(--branch-light-aquamarine);
}

.customer-review__footer-medical-entity {
    padding: 0;
    margin: 0;
    font-size: .8rem;
    color: var(--branch-light-aquamarine);
}



/* telmedia 
finaznas agendaminetos de citas
historia clinica */