.hamburger-menu-container {
    position: relative;
    align-self: center;
    justify-self: center;
    margin: auto;
    display: flex;
}

.hamburger-menu__main-page-icon {
    align-self: center;
}

.background-black-overlay {
    position: fixed;
    top: -5vh;
    right: 0vw;
    width: 100vw;
    height: 150vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 5;
}

.hamburger-menu__options-container {
    background-color: white;
    z-index: 10;
    width: 103vw;
    height: 100vh;
   /*  height: fit-content; */
    position: fixed;
    top: 0vh;
    right: 0vw;
    padding-block: 24px;
  /*  border-bottom-left-radius: 8px;
   border-top-left-radius: 8px; */
}

.modules-links-container-hamburger-menu {
    padding-block: 4px;
    padding-inline: 5%;
}

.resources-links-hamburguer-menu {
    list-style: none;
    padding-inline-start: 10%;
    margin-block: 4px;
}

.resources-links-hamburguer-menu li {
    text-align: start;
    padding-block: 4px;
    font-size: .9rem;
    font-weight: bold;
    color: var(--primary-color);
}

.modules-links-hamburger-menu {
    list-style: none;
    margin-block: 0px;
    padding-inline: 5%;
}

.modules-links-hamburger-menu li {
    text-align: start;
    padding-block: 4px;
    font-size: .9rem;
    font-weight: bold;
}

.modules-links-hamburger-menu a, .resources-links-hamburguer-menu a {
    text-decoration: none;
}

.modules-links-hamburger-menu a:visited,  .resources-links-hamburguer-menu a:visited {
    color: var(--primary-color);
}

.hamburger-menu__price-button {
    background-color: white;
    color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border: 1px solid var(--branch-opaque-aquamarine);
    border-radius: 8px;
    align-self: center;
    width: 234px;
    margin-inline: auto;
    margin-block: 16px;
}


.hamburger-menu__price-button > a {
    color: var(--branch-light-aquamarine);
    text-decoration: none;
}

.hamburger-menu__menu-item__container {
    display: flex;
    justify-content: space-between;
    padding-block: 4px;
    padding-inline: 5%;
}

.hamburger-menu__menu-entries-container {
    display: flex;
    flex-direction: column;
    padding-block-start: 24px;
    padding-block-end: 48px;
}

.hamburger-menu__menu-item {
    text-align: start;
    margin-block: 0px;
    align-self: center;
    font-size: .9rem;
    font-weight: bold;
    color: var(--primary-color);
}

.hamburger-menu__header {
    display: flex;
    padding-inline: 4%;
    width: 100%;
    padding-block-end: 36px;
    justify-content: space-between;
}

.hamburger-menu__header-img {
    min-width: 90px;
    max-width: 120px;
    height: auto;
    object-fit: cover;
}

.hamburger-menu__down-icon {
    align-self: center;
}

.hamburger-menu__down-icon-rotation-enter {
    
}

.hamburger-menu__down-icon-rotation-enter-active {
    transform: rotate(180deg);
    transition-duration: 500ms;
    transition-property: transform;
    transition-timing-function: ease;
}

.hamburger-menu__down-icon-rotation-enter-done {
    transform: rotate(180deg);
}

.hamburger-menu__down-icon-rotation-exit {
    transform: rotate(180deg);
}


.hamburger-menu__down-icon-rotation-exit-active {
    transform: rotate(0deg);
    transition-duration: 500ms;
    transition-property: transform;
    transition-timing-function: ease;
}

.hamburger-menu__down-icon-rotation-exit-done {
    transform: rotate(0deg);
}

.hamburger-menu__modules-items-menu {
    height: 0px;
    overflow: hidden;
}


.hamburger-menu__modules-items-menu-enter-active {
    height: 180px;
    transition: height 1000ms ease;
}

.hamburger-menu__modules-items-menu-enter-done {
    height: 180px;
}

.hamburger-menu__modules-items-menu-exit {
    height: 0px;
    transition: height 1000ms ease;
}


.hamburger-menu__resources-items-menu {
    height: 0px;
    overflow: hidden;
}


.hamburger-menu__resources-items-menu-enter-active {
    height: 90px;
    transition: height 1000ms ease;
}

.hamburger-menu__resources-items-menu-enter-done {
    height: 90px;
}

.hamburger-menu__resources-items-menu-exit {
    height: 0px;
    transition: height 1000ms ease;
}



