.service-section__container {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.service-section__icon-container {

}

.service-section__icon {
    width: 100%;
    max-width: 70px;
    min-width: 40px;
    object-fit: cover;
    margin: auto;
}

.service-section__title {
    color: var(--primary-color);
    font-size: 0.75rem;
    text-align: center;
    width: 90%;
    margin: auto;
}