.for-who-section {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background-color: var(--branch-gray);
    flex-wrap: wrap;
}

.for-who-section__vertical-divider {
    width: 2px;
    background-color: white;
}

 @media only screen and (min-width: 768px) {
    .for-who-section {
        flex-direction: row;
        padding-inline-start: 48px;
        padding-inline-end: 28px;
        padding-block-start: 24px;
        padding-block-end: 24px;
        width: 100%;
    }
 }