.product-service-list-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    margin: auto;
    padding: 48px 16px;
    background-color: var(--branch-gray);
    opacity: 1;
    width: 100%;
}

.product-service-list__title-container {
    width: 100%;
}

.product-service-list__title-container-title {
    width: 100%;
    color: var(--primary-color);
    margin: auto;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
}

.product-service-list__title-container-description {
    width: 90%;
    margin: 30px auto;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.7rem;
    color: var(--primary-color);
    text-align: justify;
}

.product-service-list__title-container-button {
    display: flex;
    justify-content: flex-start;
    padding: 0px 48px;
    margin: 50px auto;
}

.product-service-list__title-container-button-content {
    color: white;
    font-weight: var(--h1-default-weight);
    padding: 10px 15px;
    margin: auto;
    text-decoration: none;
    background-color: var(--branch-opaque-aquamarine);
    border-radius: 5px;
}

.product-service-list__title-container-content {
    object-fit: cover;
    align-self: center;
    width: 100%;
}


@media only screen and (min-width: 768px) {

    .product-service-list__title-container-title {
        width: 80%;
        color: var(--primary-color);
        margin: auto;
        font-weight: bold;
        font-size: 2rem;
        line-height: 3rem;
    }

    .product-service-list-section {
        display: flex;
        flex-wrap: wrap;
        max-width: 1080px;
        justify-content: space-around;
        align-content: center;
        margin: auto;
        padding: 48px 24px;
        background-color: var(--branch-gray);
        opacity: 1;
        width: 90%;
    }

    .product-service-list__title-container-description {
        width: 80%;
        margin: 30px auto;
        font-weight: 400;
        font-size: 1.25rem;
        color: var(--primary-color);
        text-align: center;
    }

    .product-service-list__title-container-button {
        display: flex;
        justify-content: flex-start;
        padding: 0px 55px;
        margin: 50px auto;
    }

    .product-service-list__title-container {
        margin-block-end: 48px;
    }
    
}