.question-container {
    background-color: white;
    box-shadow: 0 2.5px 0.5px 1px rgba(125, 125, 125, 0.07);
    margin-block: 8px;
    width: 100%;
    height: fit-content;
    margin-inline: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 8px;
}

.question__question {
    font-size: .8rem;
    margin-inline: 8px;
    cursor: pointer;
    text-align: start;
}

.question-container_visible-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.question__icon-container {
    width: 50px;
    height: 50px;
}

.question-container_answer-container {
    width: 90%;
    overflow: hidden;
    margin: auto;
}

.question-container_answer-container p {
    font-size: 0.75rem;
    text-align: start;
    padding-block: 16px;

}

.question_anim {
    overflow: hidden;
}

.question_anim-enter{
    height: 0px;
 }

 .question_anim-enter-active{
     height: 125px;
    transition: all 500ms ease;
}

.question_anim-exit-active {
    height: 0px;
    transition: all 500ms ease;
}

.question_anim-enter-active-done{
    
}


@media only screen and (min-width: 768px) {
    .question-container {
        box-shadow: 0 2.5px 0.5px 1px rgba(125, 125, 125, 0.07);
        margin-block: 8px;
        width: 75%;
        margin-inline: auto;
        padding: 16px;
        height: fit-content;
    }

    .question__question {
        font-size: 1.0rem;
        margin-inline: 16px;
    }

    .question-container_answer-container p {
        font-size: 0.9rem;
        text-align: start;
        padding-block: 16px;
    
    }

}