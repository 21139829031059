.eclinic-description-section {
    background-color: white;
    width: 100%;
}

.eclinic-description-container {
    padding: 50px 0;
}

.eclinic-description-2-subsection {
    width: 100%;
    background-color: var(--branch-gray);
    padding-block: 36px;
}

.eclinic-description-container__title, .eclinic-description-2-container__title, .eclinic-description-3-container__title {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--primary-color);
    width: 100%;
    margin-block: 16px;
    font-weight: bold;
    text-align: center;
}

.eclinic-description-3-container__description {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    height: auto;
    order: 2;
}

.eclinic-description-container__title span{
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--branch-light-aquamarine);
}

.eclinic-description-container__text, .eclinic-description-2-container__text {
    color: var(--primary-color);
    width: 90%;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
    margin: 8px auto;
}

.eclinic_description__image {
    height: auto;
    object-fit: cover;
    width: 100%;
}

.eclinic-description-2-container__img {
    max-width: 280px;
    width: 100%;
    height: auto;
    margin: auto;
}

.featurechecks, .eclinic-description-3_features {
   padding: 16px;
   padding-inline-start: 32px;
}

.eclinic-description-3-container__container {
    display: flex;
    max-width: 280px;
    width: 100%;
    height: auto;
    order: 1;
    margin: auto;
}

.eclinic-description-3 {
    width: 100%;
    margin: auto;
    background-color: rgba(217, 251, 255, .45);
}

.eclinic-description-3-container {
    display: flex;
    width: 100%;
    max-width: 1080px;
    margin: auto;
    height: auto;
    padding: 50px 0;
    flex-wrap: wrap;
}

.eclinic-description-4 {
    width: 100%;
    background-color: white;
}

.eclinic-description-4-container { 
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    width: 100%;
    margin: auto;
    height: auto;
    padding: 50px 0;
    flex-wrap: wrap;
 
}

.eclinic-description-3__marketplace-video {
    height: 80vh;
    margin: auto;
}

.eclinic-description-3-container__text {
    color: var(--primary-color);
    text-align: justify;
    font-size: 1rem;
    width: 90%;
    line-height: 1.5rem;
    margin: 24px auto;
}

.featureimage {
           order: 2;
           display: flex;
           justify-items: center;
           align-items: center;
           width: 100%;
 }

 .eclinic-description-1-container {
     width: 100%;
     padding-inline-start: 0px;
 }


@media only screen and (min-width: 768px) {

    .eclinic-description-container {
        display: flex;
        width: 90%;
        max-width: 1080px;
        margin: auto;
 /*        grid-template-columns: 25% 25% 25% 25%;
        grid-template-areas: 'feature feature featureimage featureimage'
                             'feature feature featureimage featureimage'
                             'featurechecks featurechecks featureimage featureimage'; */
        height: auto;
        padding: 50px 0;
    }

    .eclinic-description-1-container {
        width: 100%;
    }

    .eclinic-description-2-container {
        display: flex;
        width: 90%;
        max-width: 1080px;
        margin: auto;
        height: auto;
        padding: 50px 0;
        background-color: var(--branch-gray);
    }

    .eclinic-description-4-container { 
        flex-direction: row;
        width: 90%;
    }

    .eclinic-description-2-container__container {
        display: flex;
        max-width: 500px;
        width: 50%;
        height: auto;
    }

    .eclinic-description-3-container {
        width: 90%;
    }

    .eclinic-description-3-container__container {
        display: flex;
        max-width: 500px;
        width: 50%;
        height: auto;
        order: 3;
    }

    .eclinic-description-2-container__img {
        max-width: 500px;
        min-width: 280px;
        width: 100%;
        height: auto;
    }

    .eclinic-description-3_features {
        margin-inline-start: 0%;
    }

    .eclinic-description-2-container__description {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-inline-start: 16px;
        margin: auto;
        height: auto;
    }

    .eclinic-description-3-container__description {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-inline-start: 16px;
        margin: auto;
        height: auto;
        order: 1;
    }

    .eclinic-description-2-container__text {
        text-align: start;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-inline: 0px;
        margin-block: 16px;
        margin: 24px 0;
    }

    .eclinic-description-3-container__title, .eclinic-description-2-container__title, .eclinic-description-container__title { 
        text-align: start;
        font-size: 2rem;
        line-height: 3rem;
        margin-block: 8px;
    }

    .eclinic-description-3-container__text {
        text-align: start;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-block: 8px;
        margin-inline: 0px;
    }


    .eclinic-description-container__text {
        color: var(--primary-color);
        width: 100%;
        font-size: 1.25rem;
        text-align: start;
        margin: 8px 0px;
        line-height: 2rem;
    }

    .featureimage {
 /*        grid-area: featureimage; */
        order: 2;
        display: flex;
        justify-items: center;
        align-items: center;
        width: 50%;
    }

    .feature {
   /*      grid-area: feature; */
        padding-inline-start: 16px;
        order: 1;
        width: 50%;
    }

    .featurechecks {
        margin-inline-start: 0%;
     /*    grid-area: featurechecks; */
    }
}