.footer-container {
    text-align: center;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 16px;
    width: 100%;
    flex-wrap: wrap;
  }

  .footer-logo-container{
    display:flex;
    flex-direction: column;
    margin-block-end: 20px;
  }

  .footer-logo-container p {
    font-size: .75rem;
    margin-inline: auto;
    margin-block-start: 0px;
    margin-block-end: 8px;
  }
 

.eclinic-footer {
  background-color: var(--primary-color);
  padding: 16px 0;
  width: 100%;
}

  .footer-logo__image-wrapper {
    display: flex;
    justify-items: center;
    margin-block: 0;
  }

  .footer-logo__image-wrapper a {
    margin: auto;
  }

  .footer__logo {
  
    width: 100%;
    min-width: 150px;
    max-width: 220px;
    height: auto;
    justify-self: center;
}

.footer_nav-companies-entries, .footer_nav-modules-entries {
  color: white;
  list-style: none;
  text-align: start;
}

.footer_nav-companies-entries li, .footer_nav-modules-entries li {
  
  font-size: .9rem;
  margin-block: 2px;
}

.footer_entry {
  color: white;
  text-decoration: none;
}

.footer_entry:visited {
  text-decoration: none;
  color: white;
}

.eclinic-footer p {
 color: white;
/*  font-size: .9rem; */
 text-align: center;
}

.footer-logo__icons-wrapper {
  display: flex;
  justify-content: center;
  margin-block: 4px;
}

.footer-logo__icons-circular {
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  margin-inline: 8px;
}

.footer-logo__icons-wrapper a {
  margin-inline: 8px;
  align-self: center;
  justify-self: center;
  display: flex;
}

.footer-logo__icons-wrapper a svg {
  align-self: center;
  justify-self: center;
}

.footer_nav__companies-container, .footer_nav__modudes-container {
  width: 100%;
}

.footer_nav__companies-container h3, .footer_nav__modules-container h3 {
  width: 100%;
  text-align: start;
  color: white;
  font-size: 1rem;
  padding-inline-start: 40px;
}

.copyRigth {
  color: white;
  font-size: .75rem;
  margin: 16px 8px;

}

@media only screen and (min-width: 768px){
  .footer-container {
    padding: 32px 30px;
    flex-direction: row;
  }


  .footer-logo-container, .footer_nav__companies-container, .footer_nav__modules-container {
    width:33%;
  }

.eclinic-footer {
  background-color: var(--primary-color);
  padding: 16px 0;
}

  .footer-logo__image-wrapper {
    display: flex;
    justify-items: start;
  }

  .footer__logo {
    min-width: 150px;
    max-width: 250px;
    height: auto;
}

.footer_nav-entries {
  color: white;
  list-style: none;
  text-align: start;
}

.footer_nav-entries li{
  
  font-size: 1.0rem;
}

.footer_entry {
  color: white;
  text-decoration: none;
}

.footer_entry:visited {
  text-decoration: none;
  color: white;
}

.eclinic-footer p {
    color: white;
    font-size: .9rem;
    text-align: center;
    margin: auto;
  }

.footer-divider {
  background-color: white;
  height: 1px;
  margin: 24px 5%;
}

.copyRigth {
  margin: 20px 8px;
}


}