.more-questions-section {
    display: flex;
    flex-direction: column;
}

.more-questions-section__title {
    margin: auto;
}

.more-questions-section__title-text {
    font-size: 1.75rem;
    line-height: 2rem;
    color: var(--primary-color);
}

.more-questions-section__subtitle {
    font-size: 1rem;
    color: #808080;
}

.more-questions-section__features-expert {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    margin-inline: auto;
    box-shadow: 0px 0.5px 10px rgb( 0, 0, 0, .30); 
    padding-inline: 24px; 
    padding-block: 0;
}

.more-questions-section__features {
    padding: 32px;
}

.more-questions-section__to-expert-button {
    display: flex;
    padding: 32px;
}

@media only screen and (min-width: 768px) {
    .more-questions-section__features-expert {
        flex-direction: row;
    }
}