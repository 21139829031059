

.product-service-feature__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-block-start: 16px;
}

.product-service-feature__icon {
    width: 17.5px;
    height: 17.5;
    align-self: flex-start;
}

.product-service-feature__entry {
    display: flex;
    flex-direction: row;
    margin-block: 4px;
}

.product-service-feature__text {
    font-size: .75rem;
    color: var(--primary-color);
    margin-inline-start: 8px;
    text-align: start;
}